/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetListCustomerInfoEntity } from '../models/GetListCustomerInfoEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserWalletCustomerService {

    /**
     * List Customers
     * @param customerCode Search by customer's code 
     * @param customerName Search by customer's name 
     * @param customerPhoneNumber Search by customer's phonenumber 
     * @param searchDetailContent Search keyword in detail contents 
     * @param page Page
     * @param pageSize Page size
     * @returns GetListCustomerInfoEntity 
     * @throws ApiError
     */
    public static customerManageControllerGetListCustomer(
customerCode?: string,
customerName?: string,
customerPhoneNumber?: string,
searchDetailContent?: string,
page: string = '1',
pageSize: string = '10',
): CancelablePromise<GetListCustomerInfoEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/customer-manage/get-customers',
            query: {
                'customerCode': customerCode,
                'customerName': customerName,
                'customerPhoneNumber': customerPhoneNumber,
                'searchDetailContent': searchDetailContent,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

}

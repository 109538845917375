import { useMutation } from '@tanstack/react-query';
import { CheckPinCodeDto, CheckPinCodeEntity, UserWalletAuthService } from 'services/openapi';

export const useCheckPinCodeMutation = () => {
  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    CheckPinCodeEntity,
    Error,
    CheckPinCodeDto
  >({
    mutationFn: (variables: CheckPinCodeDto) => {
      return UserWalletAuthService.authControllerCheckPinCode(variables);
    },
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

import { ErrorLayout } from 'layouts';
import React, { lazy } from 'react';

import { ErrorRouteKeys } from '../enums';
import { ErrorRouteObject } from '../types';

const NotFoundError = lazy(() => import('pages/Error/Error404'));
const InternalError = lazy(() => import('pages/Error/Error500'));
const NotAccessError = lazy(() => import('pages/Error/Error403'));

export const ErrorRoutesConfig: ErrorRouteObject = {
  [ErrorRouteKeys.Root]: {
    path: ErrorRouteKeys.Root,
    element: <ErrorLayout />,
    roles: '*',
  },
  [ErrorRouteKeys.NotFound]: {
    path: ErrorRouteKeys.NotFound,
    element: <NotFoundError />,
    roles: '*',
  },
  [ErrorRouteKeys.Internal]: {
    path: ErrorRouteKeys.Internal,
    element: <InternalError />,
    roles: '*',
  },
  [ErrorRouteKeys.NotAccess]: {
    path: ErrorRouteKeys.NotAccess,
    element: <NotAccessError />,
    roles: '*',
  },
};

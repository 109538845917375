import { IInputProps } from 'modules';
import React, { TextareaHTMLAttributes } from 'react';

export const InputTextArea = (props: IInputProps & TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  const { value, onChange, onTouched, ...rest } = props;
  return (
    <textarea
      {...rest}
      onChange={(e) => onChange(e.target.value)}
      onBlur={() => setTimeout(() => onTouched(), 500)}
      value={value}
    />
  );
};

import "./styles/index.scss";

import { QueryClientProvider } from "@tanstack/react-query";
import ErrorBoundary from "components/ErrorBoundary";
import { queryClient } from "configs/client";
import { isDev } from "configs/env";
import { firebaseConfig } from "configs/firebase";
import { initializeApp } from "firebase/app";
import React, { StrictMode, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import { MainService } from "services";

// import { AppRoutes } from './AppRoutes';
import { ImageGallery } from "./components/image-gallery";
import { routesDefined } from "./routes";

const Routes = () => useRoutes(routesDefined);

const App = () => {
  useEffect(() => {
    MainService.initializeClient();

    initializeApp(firebaseConfig);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {/* <GoogleReCaptchaProvider reCaptchaKey={getEnv('GOOGLE_RECAPTCHA_KEY')}> */}
      {(isDev && (
        <StrictMode>
          <Router>
            <Routes />
          </Router>
        </StrictMode>
      )) || (
        <ErrorBoundary>
          <Router>
            <Routes />
          </Router>
        </ErrorBoundary>
      )}
      <ImageGallery />
      {/* </GoogleReCaptchaProvider> */}
      <Toaster />
    </QueryClientProvider>
  );
};

export default App;

import React from 'react';
import { MainRouteKeys } from 'routes/enums';
// import { TFunction } from 'i18next';
import { MainRoutesConfig } from 'routes/main/config';
import { checkValidRole } from 'utils/helpers';

export const menusRenderer = () => {
  const menuItems = [
    {
      ...MainRoutesConfig[MainRouteKeys.Personal],
      childs: [
        MainRoutesConfig[MainRouteKeys.PersonalInfo],
        MainRoutesConfig[MainRouteKeys.PersonalChangePassword],
        MainRoutesConfig[MainRouteKeys.PersonalBalance],
        MainRoutesConfig[MainRouteKeys.BankManagement],
        MainRoutesConfig[MainRouteKeys.PersonalLogout],
      ],
    },
    {
      ...MainRoutesConfig[MainRouteKeys.RequestWithdraw],
      childs: [
        MainRoutesConfig[MainRouteKeys.CreateRequestWithdraw],
        MainRoutesConfig[MainRouteKeys.RequestWithdrawOwn],
        MainRoutesConfig[MainRouteKeys.CheckRequestWithdrawList],
      ],
    },
    {
      ...MainRoutesConfig[MainRouteKeys.PayBill],
      childs: [MainRoutesConfig[MainRouteKeys.PayBillElectric]],
    },
    {
      ...MainRoutesConfig[MainRouteKeys.Transactions],
      childs: [MainRoutesConfig[MainRouteKeys.TransactionBills], MainRoutesConfig[MainRouteKeys.PointTransactions]],
    },
    MainRoutesConfig[MainRouteKeys.Orders],
    {
      ...MainRoutesConfig[MainRouteKeys.Dashboard],
      childs: [
        MainRoutesConfig[MainRouteKeys.DashboardReportByPC],
        MainRoutesConfig[MainRouteKeys.DashboardReportByAgency],
      ],
    },
    MainRoutesConfig[MainRouteKeys.Users],
    // {
    //   ...MainRoutesConfig[MainRouteKeys.Balance],
    //   childs: [MainRoutesConfig[MainRouteKeys.TopUp], MainRoutesConfig[MainRouteKeys.Transfers]],
    // },
    // MainRoutesConfig[MainRouteKeys.ElectricBill],
    MainRoutesConfig[MainRouteKeys.Customers],
    {
      ...MainRoutesConfig[MainRouteKeys.TPBank],
      childs: [
        MainRoutesConfig[MainRouteKeys.AccountBank],
        MainRoutesConfig[MainRouteKeys.TransactionsBank],
      ],
    },
    // MainRoutes[MainRouteKeys.Transactions],
    // MainRoutes[MainRouteKeys.Transfer],

  ];

  return menuItems
    .filter((item) => {
      return checkValidRole(item.roles);
    })
    .map((item) => {
      return {
        title: item.name,
        path: item.path,
        icon: item?.icon || <></>,
        childs: (item?.childs || [])
          .filter((child: any) => {
            return checkValidRole(child.roles);
          })
          .map((child) => {
            return {
              title: child.name,
              path: child.path,
              icon: child?.icon || <></>,
            };
          }),
      };
    });
};

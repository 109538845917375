import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';

type Props = {
  avatar?: string;
  onChangeCallback?: (file: File) => void;
};

const AvatarUploader = (props: Props) => {
  const { onChangeCallback, avatar } = props;

  const [avatarState, setAvatarState] = useState<string>(avatar || '');

  useEffect(() => {
    setAvatarState(avatar || '/assets/images/no-image.jpg');
  }, [avatar]);

  const handleDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setAvatarState(reader.result as string);

      // const blob = new Blob([reader.result as ArrayBuffer], { type: file.type });

      onChangeCallback?.(file);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Dropzone onDrop={handleDrop} accept="image/*">
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className="secondary-info__avatar-section__avatar">
          <input {...getInputProps()} />
          {avatarState ? (
            <img src={avatarState} className="secondary-info__avatar-section__avatar__img" alt="Avatar" />
          ) : (
            <div className="secondary-info__avatar-section__avatar__placeholder">
              Drag and drop an image here, or click to select an image.
            </div>
          )}
        </div>
      )}
    </Dropzone>
  );
};

export default AvatarUploader;

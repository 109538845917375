import cx from 'classnames';
import { Icon } from 'components';
import React from 'react';

type Props = {
  className?: string;
  isLoading?: boolean;
  backdrop?: boolean;
  children?: React.ReactNode;
};

const Loading = (props: Props): JSX.Element => {
  const { isLoading, className, backdrop, children } = props;

  return (
    <div className={cx('loading', className)}>
      {isLoading && (
        <>
          <Icon.Loading />
          <div className="loading__overlay">{backdrop && <div className="backdrop" />}</div>
        </>
      )}
      {children}
    </div>
  );
};

export default Loading;

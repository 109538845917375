/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApproveWithdrawRequestDto } from '../models/ApproveWithdrawRequestDto';
import type { ApproveWithdrawRequestEntity } from '../models/ApproveWithdrawRequestEntity';
import type { CreateWithdrawRequestDto } from '../models/CreateWithdrawRequestDto';
import type { CreateWithdrawRequestEntity } from '../models/CreateWithdrawRequestEntity';
import type { GetAllListWithdrawRequestEntity } from '../models/GetAllListWithdrawRequestEntity';
import type { GetDetailWithdrawRequestEntity } from '../models/GetDetailWithdrawRequestEntity';
import type { GetOwnListWithdrawRequestEntity } from '../models/GetOwnListWithdrawRequestEntity';
import type { RejectWithdrawRequestDto } from '../models/RejectWithdrawRequestDto';
import type { RejectWithdrawRequestEntity } from '../models/RejectWithdrawRequestEntity';
import type { UploadTransactionImageDto } from '../models/UploadTransactionImageDto';
import type { UploadTransactionImageEntity } from '../models/UploadTransactionImageEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WithdrawRequestService {

    /**
     * @param requestBody 
     * @returns CreateWithdrawRequestEntity 
     * @throws ApiError
     */
    public static withdrawRequestControllerCreate(
requestBody: CreateWithdrawRequestDto,
): CancelablePromise<CreateWithdrawRequestEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/withdraw-requests',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param fromTime 
     * @param toTime 
     * @param phoneNumber 
     * @param status 
     * @param page 
     * @param size 
     * @returns GetAllListWithdrawRequestEntity 
     * @throws ApiError
     */
    public static withdrawRequestControllerGetAllList(
fromTime: string,
toTime: string,
phoneNumber?: string,
status?: 'Thành công' | 'Từ chối' | 'Đang xử lý' | 'Tất cả',
page?: string,
size?: string,
): CancelablePromise<GetAllListWithdrawRequestEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/withdraw-requests',
            query: {
                'phoneNumber': phoneNumber,
                'fromTime': fromTime,
                'toTime': toTime,
                'status': status,
                'page': page,
                'size': size,
            },
        });
    }

    /**
     * @param fromTime 
     * @param toTime 
     * @param status 
     * @param page 
     * @param size 
     * @returns GetOwnListWithdrawRequestEntity 
     * @throws ApiError
     */
    public static withdrawRequestControllerGetOwnList(
fromTime: string,
toTime: string,
status?: 'Thành công' | 'Từ chối' | 'Đang xử lý' | 'Tất cả',
page?: string,
size?: string,
): CancelablePromise<GetOwnListWithdrawRequestEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/withdraw-requests/own',
            query: {
                'fromTime': fromTime,
                'toTime': toTime,
                'status': status,
                'page': page,
                'size': size,
            },
        });
    }

    /**
     * @param id 
     * @returns GetDetailWithdrawRequestEntity 
     * @throws ApiError
     */
    public static withdrawRequestControllerGetDetail(
id: string,
): CancelablePromise<GetDetailWithdrawRequestEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/withdraw-requests/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns UploadTransactionImageEntity 
     * @throws ApiError
     */
    public static withdrawRequestControllerUploadTransactionImage(
id: string,
requestBody: UploadTransactionImageDto,
): CancelablePromise<UploadTransactionImageEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/withdraw-requests/{id}/upload-transaction-image',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns ApproveWithdrawRequestEntity 
     * @throws ApiError
     */
    public static withdrawRequestControllerApprove(
id: string,
requestBody: ApproveWithdrawRequestDto,
): CancelablePromise<ApproveWithdrawRequestEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/withdraw-requests/{id}/approve',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns RejectWithdrawRequestEntity 
     * @throws ApiError
     */
    public static withdrawRequestControllerReject(
id: string,
requestBody: RejectWithdrawRequestDto,
): CancelablePromise<RejectWithdrawRequestEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/withdraw-requests/{id}/reject',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}

import { Loading } from 'components';
import React from 'react';
import { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { ErrorRouteKeys, MainRouteKeys } from 'routes/enums';
import { MainRoutesConfig } from 'routes/main/config';
import { checkValidRole, getAccessToken, shouldLogout } from 'utils/helpers';
import { useMatchedRoute } from 'utils/hooks';

const RouterSuspense = ({ children }: { children: React.ReactNode }) => {
  const accessToken = getAccessToken();
  const navigate = useNavigate();

  const route = useMatchedRoute(Object.values(MainRouteKeys));
  const currentRoute = MainRoutesConfig[route as MainRouteKeys];

  const redirectUrl = currentRoute?.redirectUrl;

  useEffect(() => {
    if (redirectUrl) {
      navigate(redirectUrl, { replace: true });
    }
  }, [redirectUrl, navigate]);

  useEffect(() => {
    if (accessToken && !shouldLogout(accessToken)) {
      const isValid = checkValidRole(currentRoute?.roles);
      if (!isValid) {
        navigate(ErrorRouteKeys.NotFound, {
          replace: true,
        });
      }
    }
  }, [accessToken, currentRoute, navigate]);

  if (!accessToken || shouldLogout(accessToken)) {
    return <Loading backdrop />;
  }

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default RouterSuspense;

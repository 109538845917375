import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RequestWithdrawQueryKeys } from '../constants';
import { WithdrawRequestService, CreateWithdrawRequestDto, CreateWithdrawRequestEntity, ApproveWithdrawRequestEntity, ApproveWithdrawRequestDto } from 'services/openapi';

export type AcceptRequestWithdrawType = {
  id: string;
  variables: ApproveWithdrawRequestDto;
}

export const useApproveRequestWithdrawMutation = () => {
  const queryClient = useQueryClient();

  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    ApproveWithdrawRequestEntity,
    Error,
    AcceptRequestWithdrawType
  >({
    mutationFn: (request: AcceptRequestWithdrawType) => {
      return WithdrawRequestService.withdrawRequestControllerApprove(request.id, request.variables);
    },
    onSuccess: () => Promise.all([queryClient.invalidateQueries([RequestWithdrawQueryKeys.APPROVE_REQUEST_WITHDRAW])]),
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

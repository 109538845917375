import { translate } from './languages';
import { IModuleConfig } from './modules/types';
import { ELocale } from './types';

const moduleConfig: IModuleConfig = {
  translate: (id: string, values?: any) => translate(id, values),
  getLocaleList: () => [
    {
      key: ELocale.VIETNAM,
      label: 'VN',
      isActive: true,
    },
    {
      key: ELocale.ENGLISH,
      label: 'ENG',
      isActive: true,
    },
  ],
};

export default moduleConfig;

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';

  return phoneNumber.replace(/^(0|84|084)(\d{9})$/, '+84$2');
};

export const validatePhoneNumber = (phoneNumber: string) => {
  const regex = /^(0|\+84)(3[1-9]|5[689]|7[0|6-9]|8[1-9]|9[0-9])[0-9]{7}$/;

  return regex.test(phoneNumber);
};

import { useQuery } from '@tanstack/react-query';
import { GetUserBalanceEntity, UserWalletTransactionService } from 'services/openapi';

import { TransactionServiceKeys } from './constants';

export const useGetBalanceQuery = () => {
  const queryKey = [TransactionServiceKeys.GET_BALANCE];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    GetUserBalanceEntity,
    Error
  >({
    queryKey,
    queryFn: () => UserWalletTransactionService.transactionControllerGetUserBalance(),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data,
    isError,
    queryKey,
    refetch,
  };
};

import capitalize from 'lodash/capitalize';
import { Store } from 'redux';

import { RequestMainService } from '../request';
import { SET_COIN_CONVERSION_RATE } from './coinConversionRate.reducer';
export interface IRequestWithdrawPayload {
  coinId: number;
  feeCoinId: number;
  value: number;
}

export class BankService {
  static async getTransactions(params: any = {}) {
    return RequestMainService.get(`/bank/transaction`, params).then(({ result }) => ({
      count: result.total,
      data: result.transactions,
    }));
  }

  static async getCoinConversionRate(store: Store) {
    return RequestMainService.get(`/bank/rates-to-usd`)
      .then(({ result }) => store.dispatch({ type: SET_COIN_CONVERSION_RATE, data: result }))
      .catch((res) =>
        store.dispatch({
          type: SET_COIN_CONVERSION_RATE,
          error: res.error,
        }),
      );
  }

  static getTransactionTypeOptions() {
    return [
      { label: 'SEND_TRANSFER', value: 1 },
      { label: 'RECEIVE_TRANSFER', value: 2 },
      { label: 'ADMIN_TRANSFER', value: 3 },
    ].map((v) => ({ ...v, label: capitalize(v.label.replace(/_/g, ' ')) }));
  }

  static getStatusOptions() {
    return [
      { label: 'JUST_CREATED', value: 'JUST_CREATED' },
      { label: 'SUCCESS', value: 'SUCCESS' },
      { label: 'REJECTED', value: 'REJECTED' },
      { label: 'PROCESSING', value: 'PROCESSING' },
    ].map((v) => ({ ...v, label: capitalize(v.label.replace(/_/g, ' ')) }));
  }
}

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponseEntity } from '../models/BaseResponseEntity';
import type { CreateUserDto } from '../models/CreateUserDto';
import type { CreateUserEntity } from '../models/CreateUserEntity';
import type { GetListLeadersEntity } from '../models/GetListLeadersEntity';
import type { GetListUserEntity } from '../models/GetListUserEntity';
import type { GetOneUserEntityReturn } from '../models/GetOneUserEntityReturn';
import type { UpdatePointToUserDto } from '../models/UpdatePointToUserDto';
import type { UpdatePointToUserEntity } from '../models/UpdatePointToUserEntity';
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { UpdateUserInfoEntity } from '../models/UpdateUserInfoEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserWalletManageService {

    /**
     * @param requestBody 
     * @returns CreateUserEntity 
     * @throws ApiError
     */
    public static userManageControllerCreateUser(
requestBody: CreateUserDto,
): CancelablePromise<CreateUserEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/manager/create-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Infomation invalid, Company not exists leader, Fail Transaction`,
                401: `Unauthorized`,
                403: `Forrbidden`,
                409: `User already exists, Company already exists Leader`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UpdateUserInfoEntity 
     * @throws ApiError
     */
    public static userManageControllerUpdateUserInfo(
requestBody: UpdateUserDto,
): CancelablePromise<UpdateUserInfoEntity> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/user-wallet/manager/update-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Infomation invalid, Company not exists leader, Fail Transaction`,
                401: `Unauthorized`,
                403: `Forrbidden`,
                404: `User not found`,
                406: `Cannot Remove Leader has Agency,Cannot Update Leader has Agency`,
                409: `Company already exists Leader`,
            },
        });
    }

    /**
     * @param userId search string
     * @returns BaseResponseEntity 
     * @throws ApiError
     */
    public static userManageControllerActiveUser(
userId: string,
): CancelablePromise<BaseResponseEntity> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/user-wallet/manager/active-user/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                400: `user is undefined, User already activated, User has been deleted`,
                401: `Unauthorized`,
                403: `Forrbidden`,
                409: `Company already exists Leader`,
            },
        });
    }

    /**
     * @param userId search string
     * @returns BaseResponseEntity 
     * @throws ApiError
     */
    public static userManageControllerInactiveUser(
userId: string,
): CancelablePromise<BaseResponseEntity> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/user-wallet/manager/inactive-user/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                400: `user is undefined, User already deactivated, User has been deleted`,
                401: `Unauthorized`,
                403: `Forrbidden`,
            },
        });
    }

    /**
     * @param userId search string
     * @returns BaseResponseEntity 
     * @throws ApiError
     */
    public static userManageControllerRemoveUser(
userId: string,
): CancelablePromise<BaseResponseEntity> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/user-wallet/manager/remove-user/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                400: `User has been deleted`,
                401: `Unauthorized`,
                403: `Forrbidden`,
                404: `User not found`,
                406: `Cannot Remove Leader has Agency`,
            },
        });
    }

    /**
     * List users
     * @param searchString Search by user's name or phone number
     * @param page Page
     * @param pageSize Page size
     * @param isActive Is active
     * @param role Role
     * @param leader Leader's full name-phone number
     * @param searchDetailContent Search keyword in detail contents 
     * @returns GetListUserEntity 
     * @throws ApiError
     */
    public static userManageControllerGetListUser(
searchString?: string,
page: string = '1',
pageSize: string = '10',
isActive?: boolean,
role?: 'Agency' | 'Leader' | 'SpVisor',
leader?: string,
searchDetailContent?: string,
): CancelablePromise<GetListUserEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/manager/get-list',
            query: {
                'searchString': searchString,
                'page': page,
                'pageSize': pageSize,
                'isActive': isActive,
                'role': role,
                'leader': leader,
                'searchDetailContent': searchDetailContent,
            },
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UpdatePointToUserEntity 
     * @throws ApiError
     */
    public static userManageControllerAddPointToUser(
requestBody: UpdatePointToUserDto,
): CancelablePromise<UpdatePointToUserEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/manager/add-point',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Value can't less than or equal 0, Insufficient balance`,
                401: `Unauthorized`,
                403: `Forrbidden`,
                404: `User not found, Can't get recipient information`,
                500: `INTERNAL_SERVER_ERROR`,
            },
        });
    }

    /**
     * @param amount Amount for transfer
     * @param toUserId to userId
     * @param reason Descripton transfer
     * @returns UpdatePointToUserEntity 
     * @throws ApiError
     */
    public static userManageControllerSubtractPointToUser(
amount: number,
toUserId: string,
reason: string,
): CancelablePromise<UpdatePointToUserEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/manager/subtract-point',
            path: {
                'amount': amount,
                'toUserId': toUserId,
                'reason': reason,
            },
            errors: {
                400: `Value can't less than or equal 0, Insufficient balance`,
                401: `Unauthorized`,
                403: `Forrbidden`,
                404: `User not found, Can't get recipient information`,
                500: `INTERNAL_SERVER_ERROR`,
            },
        });
    }

    /**
     * List leaders
     * @returns GetListLeadersEntity 
     * @throws ApiError
     */
    public static userManageControllerGetListLeaders(): CancelablePromise<GetListLeadersEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/manager/get-leaders',
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param userId userId
     * @returns GetOneUserEntityReturn 
     * @throws ApiError
     */
    public static userManageControllerGetOneUser(
userId: string,
): CancelablePromise<GetOneUserEntityReturn> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/manager/get-user/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                400: `User is deleted, System error`,
                401: `Unauthorized`,
                403: `Forrbidden`,
                404: `User not found`,
            },
        });
    }

}

import { useQuery } from '@tanstack/react-query';
import { ProfileUserEntity, UserWalletAuthService } from 'services/openapi';

import { UserServiceQueryKeys } from '../constants';

export const useGetUserProfileQuery = () => {
  const queryKey = [UserServiceQueryKeys.GET_USER_PROFILE];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<ProfileUserEntity, Error>({
    queryKey,
    queryFn: () => UserWalletAuthService.authControllerGetProfileUser(),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: Infinity,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data,
    isError,
    queryKey,
    refetch,
  };
};

import { useQuery } from '@tanstack/react-query';
import { GetBillsEntity, UserWalletServicesService } from 'services/openapi';

import { ElectricQueryKeys } from './constants';
import { isEmpty } from 'lodash';

type GetElectricBillsQuery = {
  customerCodes: string;
};

export const useGetElectricBillsQuery = (variables: GetElectricBillsQuery) => {
  const queryKey = [ElectricQueryKeys.GET_ELECTRIC_BILLS, variables];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<GetBillsEntity, Error>({
    queryKey,
    queryFn: () => UserWalletServicesService.servicesControllerGetOrders(variables.customerCodes),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: !isEmpty(variables) && !isEmpty(variables.customerCodes),
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};

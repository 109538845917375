import { Header, PageHeader, SideBar } from 'components';
import { RouterSuspense } from 'components';
import React from 'react';
import { Outlet } from 'react-router-dom';

import AuthWrapper from '../Wrapper';

const MainLayout = () => {
  return (
    <AuthWrapper>
      <main className="d-flex">
        <SideBar />
        <div className="main-content flex-grow-1">
          <div className="container-fluid content pdt16 pdb16 pdl32 pdr32">
            <Header />
            <PageHeader />
            <RouterSuspense>
              <Outlet />
            </RouterSuspense>
          </div>
        </div>
      </main>
    </AuthWrapper>
  );
};

export default MainLayout;

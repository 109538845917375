import 'react-calendar/dist/Calendar.css';
import 'react-datetime-picker/dist/DateTimePicker.css';

import { IInputProps } from 'modules';
import React, { FC, useEffect, useState } from 'react';
// @ts-ignore
import DatePicker from 'react-date-picker';

export const InputDatePicker: FC<IInputProps> = (props) => {
  const [value, setValue] = useState(null) as any;

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue, setValue]);

  const handleChange = (date: any) => {
    let time: any;
    if (date) time = new Date(date).getTime();

    setValue(time);

    props.onChange(new Date(time).toUTCString());

    props.onChangeCallback?.(new Date(time).toUTCString());
  };

  const convertTime = (time: any) => {
    if (!time) return;
    return new Date(time);
  };

  return (
    <DatePicker
      className="InputDateTime"
      calendarClassName="InputDateTimeCalendar"
      onChange={handleChange}
      value={convertTime(value)}
      format="dd/MM/yyyy"
      locale="vi-VN"
      onCalendarClose={() => props.onTouched()}
      disabled={props.isDisabled}
    />
  );
};

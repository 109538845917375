import Breadcrumbs from 'components/Breadcrumbs';
import React, { memo } from 'react';

import { HeaderButtonOutlet } from '../HeaderButton';

const PageHeader = (): JSX.Element => {
  return (
    <div className="PageHeader">
      <div className="d-flex">
        <Breadcrumbs />
        <HeaderButtonOutlet />
      </div>
    </div>
  );
};

export default memo(PageHeader);

import { useMutation } from '@tanstack/react-query';
import { ChangePinCodeDto, ChangePinCodeEntity, UserWalletAuthService } from 'services/openapi';

export const useChangePinCodeMutation = () => {
  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    ChangePinCodeEntity,
    Error,
    ChangePinCodeDto
  >({
    mutationFn: (variables: ChangePinCodeDto) => {
      return UserWalletAuthService.authControllerChangePinCode(variables);
    },
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

import { Icon } from 'components/icon';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { ItemTypes } from './MenuItem';

type Props = {
  onClickCallback?: () => void;
  data: ItemTypes;
  haveChild?: boolean;
};

const Item: React.FC<Props> = ({ data, haveChild, onClickCallback }) => {
  const { title, path, icon } = data;

  return (
    <NavLink to={path} onClick={onClickCallback}>
      <span className="title">
        {icon}
        <span>{title}</span>
      </span>

      {haveChild && (
        <div
          onClick={(e) => {
            e.preventDefault();
            onClickCallback?.();
          }}
        >
          <Icon.ArrowDown />
        </div>
      )}
    </NavLink>
  );
};

export default Item;

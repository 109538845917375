import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  PayBillFeesByCustomerCodeDto,
  PayBillOrderResultEntityReturn,
  UserWalletServicesService,
} from 'services/openapi';

import { ElectricQueryKeys } from '../queries';

export const useElectricPayBillMutation = () => {
  const queryClient = useQueryClient();

  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    PayBillOrderResultEntityReturn,
    Error,
    PayBillFeesByCustomerCodeDto
  >({
    mutationFn: (variables: PayBillFeesByCustomerCodeDto) => {
      return UserWalletServicesService.servicesControllerPayBill(variables);
    },
    onSuccess: () => Promise.all([queryClient.invalidateQueries([ElectricQueryKeys.GET_ELECTRIC_BILLS])]),
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

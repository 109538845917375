import { useQuery } from '@tanstack/react-query';
import { GetListTransPayEntity, UserWalletServicesService } from 'services/openapi';

import { ElectricQueryKeys } from './constants';
import { isEmpty } from 'lodash';

type GetListTransPayDTO = {
  transactionCode?: string;
  orderCode?: string;
  status: 'Thành công' | 'Thất bại' | 'Đã hủy' | 'Đang xử lý' | 'Đang chờ hủy' | 'Tất cả';
  leader?: string;
  userExec?: string;
  serviceType?: string;
  fromDate?: string;
  toDate?: string;
  searchDetailContent?: string;
  page: string;
  pageSize: string;
};

export const useExportElectricTransactionsQuery = (variables: GetListTransPayDTO) => {
  const queryKey = [ElectricQueryKeys.GET_ELECTRIC_TRANSACTIONS, variables];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    GetListTransPayEntity,
    Error
  >({
    queryKey,
    queryFn: () =>
      UserWalletServicesService.servicesControllerGetTransaction(
        variables.transactionCode?.trim(),
        variables.orderCode?.trim(),
        variables.status,
        variables.leader?.trim(),
        variables.userExec?.trim(),
        variables.serviceType,
        variables.fromDate?.trim(),
        variables.toDate?.trim(),
        variables.searchDetailContent?.trim(),
        variables.page,
        variables.pageSize,
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: false
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};

import { IInputProps } from 'modules';
import React, { FC } from 'react';
import NumberFormat from 'react-number-format';

export const InputNumber: FC<IInputProps> = ({ value, onChange, onChangeCallback, onTouched, onKeyDown }) => {
  return (
    <NumberFormat
      fixedDecimalScale={false}
      decimalSeparator={'.'}
      thousandSeparator={','}
      value={typeof value === 'number' ? value : undefined}
      onValueChange={({ floatValue }) => {
        const data = floatValue || 0;
        onChange(data);
        onChangeCallback?.(data);
      }}
      onBlur={() => setTimeout(() => onTouched(), 500)}
      onKeyDown={onKeyDown}
    />
  );
};

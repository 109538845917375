const defaultState = null;

export const SET_AUTH = 'SET_AUTH';
export const LOG_OUT = 'LOG_OUT';

export const userReducer = (state = defaultState, action: any) => {
  const { type } = action;
  if (type === SET_AUTH) return action.data;
  if (type === LOG_OUT) return defaultState;
  return state;
};

import { useQuery } from '@tanstack/react-query';
import { SearchPhoneNumberEntity, UserWalletTransferService } from 'services/openapi';
import { formatPhoneNumber } from 'utils/helpers';

import { WalletServiceKeys } from './constants';

type CheckPhoneNoQueryVariables = {
  phoneNumber: string;
};

const convertVariables = (variables: CheckPhoneNoQueryVariables) => {
  const { phoneNumber } = variables;

  return {
    phoneNumber: formatPhoneNumber(phoneNumber),
  };
};

export const useCheckPhoneNumberTransferQuery = (variables: CheckPhoneNoQueryVariables) => {
  const { phoneNumber } = convertVariables(variables);

  const queryKey = [WalletServiceKeys.CHECK_PHONE_NUMBER, { phoneNumber }];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    SearchPhoneNumberEntity,
    Error
  >({
    queryKey,
    queryFn: () => UserWalletTransferService.transferControllerSearchPhoneNumber(phoneNumber),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: false,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data,
    isError,
    queryKey,
    refetch,
  };
};

import { isEqual } from 'lodash';

const defaultState: any[] = [];

export const SET_OPENNED_ORDERS = 'SET_OPENNED_ORDERS';

export const openedOrdersReducer = (state = defaultState, action: any) => {
  const { type, data } = action;
  if (type === SET_OPENNED_ORDERS) {
    if (isEqual(data, state)) return state;
    return data;
  }
  return state;
};

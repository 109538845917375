import { Icon, IInputProps, IUseFormStructureItem, ObjectUtils } from 'modules';
import React, { FC, Fragment, useEffect, useState } from 'react';

import moduleConfig from '../../../module.config';

export interface IInputArrayDataChildStructureItem extends IUseFormStructureItem {
  isVisibleOnList?: boolean;
  render?: (rowValue: any) => any;
}

export interface IInputArrayChildFormProps {
  childFormData: any;
  structure: IInputArrayDataChildStructureItem[];
  onSubmit: (values: any) => void;
  name: string;
  isUpdateUI: boolean;
  // ============================ Events ============================
  onRemove?: (id: any) => Promise<any> | void;
  onUpdate?: (id: any, values: any) => Promise<any> | void;
}

export interface IInputArrayDataProps extends IInputProps {
  label: string;
  childForm: FC<IInputArrayChildFormProps>;
  childStructure: IInputArrayDataChildStructureItem[];
  // ============================ Events ============================
  onCreate?: (values: any) => Promise<any> | void;
  onRemove?: (id: any) => Promise<any> | void;
  onUpdate?: (id: any, values: any) => Promise<any> | void;
}

export const InputArrayData: FC<IInputArrayDataProps> = (props) => {
  const [isChildFormVisible, setIsChildFormVisible] = useState(false);
  const [childFormData, setChildFormData] = useState(null) as any;
  const data = Array.isArray(props.value)
    ? props.value.map((item, index) => ({ ...item, id: item.id || index + 1 }))
    : [];
  const [isLoading, setIsLoading] = useState(false);

  const childStructureVisible = props.childStructure.filter((v) => v.isVisibleOnList || v.render);
  const childFormId = `InputArrayData__ChildForm__${props.name}`;
  const isUpdateUI = !!childFormData;

  useEffect(() => {
    if (isChildFormVisible) {
      document.onkeyup = function (e) {
        if (e.key === 'Escape') setIsChildFormVisible(false);
      };
    }
  }, [isChildFormVisible, setIsChildFormVisible]);

  const removeItem = async (id: any) => {
    setIsLoading(true);
    try {
      if (props.onRemove) await props.onRemove(id);
      props.onChange(data.filter((v) => v.id !== id));
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <Fragment>
      <div className="InputArrayData">
        {isLoading ? (
          <div className="tableLoading">
            <Icon.Loading />
          </div>
        ) : null}

        <table>
          <thead>
            <tr>
              <th>No.</th>
              {childStructureVisible.map(({ label }, key) => {
                return <th key={key}>{label}</th>;
              })}

              <th>Actions</th>
            </tr>
          </thead>

          {data.length > 0 ? (
            <tbody>
              {data.map((childValue, childValueKey) => {
                return (
                  <tr key={childValueKey}>
                    <td>{childValueKey + 1}</td>
                    {childStructureVisible.map(({ name, render, isMutilLocale }, key) => {
                      return (
                        <td key={key}>
                          {(() => {
                            if (render) return render(childValue);
                            if (isMutilLocale)
                              return ObjectUtils.getIn(
                                childValue,
                                `${name}.${moduleConfig.getLocaleList()[0].key}`,
                                '',
                              );
                            return `${ObjectUtils.getIn(childValue, name, '')}`;
                          })()}
                        </td>
                      );
                    })}

                    <td>
                      <div
                        className="btnEdit"
                        onClick={() => {
                          setChildFormData(childValue);
                          setIsChildFormVisible(true);
                        }}
                      >
                        <Icon.Edit />
                      </div>

                      <div className="btnRemove" onClick={() => removeItem(childValue.id)}>
                        <Icon.Remove />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : null}
        </table>

        {data.length > 0 ? null : (
          <div className="Message emptyData">
            <Icon.Data />
            No data
          </div>
        )}

        <div className="ctas">
          <div className="btnAddMore" onClick={() => setIsChildFormVisible(true)}>
            Add More
          </div>
        </div>
      </div>

      {isChildFormVisible ? (
        <div
          id={childFormId}
          className="InputArrayData__ChildForm"
          onClick={(e: any) => {
            if (e.target.id === childFormId) setIsChildFormVisible(false);
          }}
        >
          <div className="wraper">
            <div className="head">
              <div className="title">
                <span onClick={() => setIsChildFormVisible(false)}>
                  <Icon.ArrowLeft />
                </span>
                {childFormData ? 'Update' : 'Add new'}: {props.label}
              </div>

              <div className="btnClose" onClick={() => setIsChildFormVisible(false)}>
                <Icon.Close />
              </div>
            </div>

            <div className="body">
              <props.childForm
                isUpdateUI={isUpdateUI}
                childFormData={childFormData}
                structure={props.childStructure}
                onSubmit={async (values) => {
                  if (isUpdateUI) {
                    try {
                      if (props.onUpdate) await props.onUpdate(childFormData.id, values);
                      props.onChange(
                        data.map((item) => {
                          if (item.id === childFormData.id) return { ...childFormData, ...values };
                          else return item;
                        }),
                      );

                      setChildFormData(null);
                      setIsChildFormVisible(false);
                    } catch (error) {
                      console.log(error);
                    }
                  } else {
                    try {
                      let id = data.length + 2;

                      try {
                        if (props.onCreate) id = (await props.onCreate(values)) || Date.now();
                      } catch (error) {}

                      props.onChange([...data, { id, ...values }]);

                      setIsChildFormVisible(false);
                    } catch (error) {
                      console.log(error);
                    }
                  }
                }}
                name={props.name}
                onRemove={props.onRemove}
                onUpdate={props.onUpdate}
              />
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

import { IInputProps } from "modules";
import React, { KeyboardEvent, useEffect, useState } from "react";

export const InputTags = (props: IInputProps) => {
  const { onChange, onChangeCallback, onKeyDown, defaultValue, ...rest } = props;

  const [tags, setTags] = useState<string[]>([]);

  const [inputValue, setInputValue] = useState<string>(); // New state variable for the input value

  useEffect(() => {
    if (defaultValue && defaultValue?.length) {
      handleOnChange([...defaultValue]);
      return;
    }
  }, [defaultValue]);

  const handleOnChange = (value: string[]) => {
    if (!value) return;

    setTags(value);

    onChange(value);

    onChangeCallback?.(value);
  };

  const addTags = (event: KeyboardEvent<HTMLInputElement>, blur = false) => {
    const { value } = event.currentTarget;

    if (!value || value === "undefined") {
      setInputValue("");
      return;
    }

    if ((event.key === "Enter" || event.key === " " || blur) && value && value.trim() !== "") {
      if (tags.find((tag) => tag === value)) {
        handleOnChange(tags);

        setInputValue("");
        return;
      }

      handleOnChange([...tags, value]);

      setInputValue("");
    }
  };

  const removeTags = (indexToRemove: number) => {
    const newTags = tags.filter((_, index) => index !== indexToRemove);

    handleOnChange(newTags);
  };

  return (
    <div className="InputTags">
      <ul id="tags">
        {tags.map((tag, index) => (
          <li key={index} className="tag">
            <span className="tag-title">{tag}</span>
            <span className="tag-close-icon" onClick={() => removeTags(index)}>
              x
            </span>
          </li>
        ))}
      </ul>

      <input
        {...rest}
        type="text"
        value={inputValue}
        defaultValue={defaultValue}
        onKeyUp={addTags}
        onChange={(e) => {
          setInputValue(e.target.value);
          onChange(e.target.value);
        }}
        onBlur={(e) => {
          e.currentTarget.value = inputValue || "";
          addTags(e as unknown as KeyboardEvent<HTMLInputElement>, true);
        }}
        onKeyDown={(e)=> {
          onKeyDown && onKeyDown(e)
        }}
      />
    </div>
  );
};

import { DEFAULT_PAGE, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'configs';

type InitFilterTypes = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: number | string;
};

export const initFilterFunction = (arg: InitFilterTypes): (() => any) => {
  return (): any => {
    return {
      pageSize: arg.pageSize || DEFAULT_PAGE_SIZE,
      page: Number(arg.pageIndex || DEFAULT_PAGE_INDEX) + 1,
      ...(arg.orderBy && { orderBy: arg.orderBy }),
      ...arg,
    };
  };
};

export const itemsPerPageOptions = [
  {
    value: 10,
    label: '10 / Trang',
  },
  {
    value: 20,
    label: '20 / Trang',
  },
  {
    value: 50,
    label: '50 / Trang',
  },
  {
    value: 100,
    label: '100 / Trang',
  },
];

import { ECookieVariable } from 'configs/cookies';
import React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthRouteKeys, ErrorRouteKeys, MainRouteKeys } from 'routes/enums';
import { MainRoutesConfig } from 'routes/main/config';
import { CookieService } from 'services';
import { OpenAPI } from 'services/openapi/core/OpenAPI';
import { checkRolesIncludeRoles, getAccessToken, getUserRoles, shouldLogout } from 'utils/helpers';

const AuthWrapper = (props: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  OpenAPI.BASE = process.env.REACT_APP_URL_API_MAIN_CLIENT_SIDE as string;

  OpenAPI.HEADERS = {
    'Accept-Language': 'vi',
    Authorization: `Bearer ${getAccessToken()}`,
  };

  useEffect(() => {
    const accessToken = getAccessToken();

    async function verifyLogin() {
      if (accessToken && !shouldLogout(accessToken)) {
        const userRoles = getUserRoles();

        if (userRoles && userRoles.length) {
          const accessRouters = Object.keys(MainRoutesConfig).filter((item) => {
            return (
              item !== MainRouteKeys.Root &&
              checkRolesIncludeRoles(userRoles, MainRoutesConfig[item as MainRouteKeys].roles)
            );
          });

          if (location.pathname.includes(AuthRouteKeys.Root) || location.pathname === MainRouteKeys.Root) {
            navigate(accessRouters?.[0] || ErrorRouteKeys.NotFound, {
              replace: true,
            });
          }
        }
      } else {
        CookieService.remove(ECookieVariable.ACCESS_TOKEN_KEY);

        navigate(AuthRouteKeys.Login, {
          replace: true,
        });
      }
    }
    verifyLogin();
  }, []);

  return <>{props.children}</>;
};

export default AuthWrapper;

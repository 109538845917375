const defaultState = null;

export const SET_USER_WALLET_BALANCES = 'SET_USER_WALLET_BALANCES';

export const userWalletBalancesReducer = (state = defaultState, action: any) => {
  const { type, data, error } = action;
  if (type === SET_USER_WALLET_BALANCES) {
    if (error) return { error };
    if (data) return data;
  }
  return state;
};

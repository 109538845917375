import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { handleLogin } from 'utils/helpers';

const onError = (error: any) => {
  if (error.message === 'unauthorized' || error.message === 'Unauthorized' || error.message === 'Hết hạn đăng nhập') {
    handleLogin();
  }
};

export const queryCache: any = new QueryCache({
  onError,
});

export const mutationCache: any = new MutationCache({
  onError,
});

export const queryClient: any = new QueryClient({
  queryCache,
  mutationCache,
});

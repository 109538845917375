import React, { FC, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';

import { Icon } from '../icon';

export interface ImageGalleryItem {
  src: string;
  thumbnail: string;
}

export let onImageGallery = (data: ImageGalleryItem[], startIndex: number = 0): any => {};
export let offImageGallery = (): any => {};

export const ImageGallery: FC = () => {
  const [state, setState] = useState({
    isOn: false,
    data: [] as ImageGalleryItem[],
    startIndex: 0,
  });

  onImageGallery = (data: ImageGalleryItem[], startIndex: number = 0) =>
    setState((state) => ({ ...state, isOn: true, data, startIndex }));
  offImageGallery = () => setState((state) => ({ ...state, isOn: false }));

  if (!state.isOn) return null;

  return (
    <div className="ImageGallery">
      <div className="btnClose" onClick={() => setState((state) => ({ ...state, isOn: false }))}>
        <Icon.Close />
      </div>
      <ReactImageGallery
        items={state.data.map((v: any) => (v = { ...v, original: v.src }))}
        startIndex={state.startIndex || 0}
      />
    </div>
  );
};

import { useQuery } from '@tanstack/react-query';
import { GetListBankAccountEntity, BankAccountService } from 'services/openapi';

import { BankAccountServiceQueryKeys } from '../constants';

export const useGetAccountBanksQuery = () => {
  const queryKey = [BankAccountServiceQueryKeys.GET_ACCOUNT_BANKS];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<GetListBankAccountEntity, Error>({
    queryKey,
    queryFn: () => BankAccountService.bankAccountControllerGetListBankAccount(),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: Infinity,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data,
    isError,
    queryKey,
    refetch,
  };
};

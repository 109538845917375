import React from 'react';
import { Component } from 'react';

type Props = {
  children: JSX.Element | JSX.Element[];
};

type State = {
  hasError?: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="vnm-h-screen vnm-w-full vnm-flex-col vnm-items-center vnm-justify-center vnm-flex">
          <p className="vnm-text-4xl vnm-font-bold">Sorry, something went wrong</p>
          <p className="vnm-text-xl">Please again try laterPlease again try later!</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

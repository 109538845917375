import { useQuery } from '@tanstack/react-query';
import { GetOneUserEntityReturn, UserWalletManageService } from 'services/openapi';

import { UserServiceQueryKeys } from '../constants';

type GetUserInfoMutationVariables = {
  userId: string;
};

export const useGetUserInfoQuery = (variables: GetUserInfoMutationVariables) => {
  const { userId } = variables;
  const queryKey = [UserServiceQueryKeys.GET_USER_INFO, { userId }];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    GetOneUserEntityReturn,
    Error
  >({
    queryKey,
    queryFn: () => UserWalletManageService.userManageControllerGetOneUser(userId),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data,
    isError,
    queryKey,
    refetch,
  };
};

import React from 'react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';

import AuthWrapper from '../Wrapper';

const AuthLayout = (): JSX.Element => {
  return (
    <AuthWrapper>
      <div className="page-auth-login">
        <div className="login-wrapper">
          <div className="row">
            <div className="col-lg-8 col-md-12 login-banner">
              <div className="banner-image">
                <img className="banner-image__img" src="/assets/images/primary-login-banner.png" alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-12 d-flex align-items-center">
              <Suspense fallback={<TopBarProgress />}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default AuthLayout;

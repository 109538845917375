import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TransactionServiceKeys } from 'services';
import { TransferToUserDto, TransferToUserEntity, UserWalletTransferService } from 'services/openapi';

export const useWalletTransferMutation = () => {
  const queryClient = useQueryClient();

  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    TransferToUserEntity,
    Error,
    TransferToUserDto
  >({
    mutationFn: (variables: TransferToUserDto) => {
      return UserWalletTransferService.transferControllerTransferToUser(variables);
    },
    onSuccess: () => Promise.all([queryClient.invalidateQueries([TransactionServiceKeys.GET_BALANCE])]),
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

export function useMatchedRoute(routes: string[]) {
  const { pathname } = useLocation();
  for (const route of routes) {
    if (matchPath(route, pathname)) {
      return route;
    }
  }
}

import { useQuery } from '@tanstack/react-query';
import { CheckPhoneNumberEntity, UserWalletAuthService } from 'services/openapi';
import { formatPhoneNumber } from 'utils/helpers';

import { UserServiceQueryKeys } from '../constants';

type CheckPhoneNoQueryVariables = {
  phoneNumber: string;
};

const convertVariables = (variables: CheckPhoneNoQueryVariables) => {
  const { phoneNumber } = variables;

  return {
    phoneNumber: formatPhoneNumber(phoneNumber),
  };
};

export const useCheckPhoneNoQuery = (variables: CheckPhoneNoQueryVariables) => {
  const { phoneNumber } = convertVariables(variables);

  const queryKey = [UserServiceQueryKeys.CHECK_PHONE_NO, { phoneNumber }];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    CheckPhoneNumberEntity,
    Error
  >({
    queryKey,
    queryFn: () => UserWalletAuthService.authControllerCheckPhoneNumber(phoneNumber),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: false,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data,
    isError,
    queryKey,
    refetch,
  };
};

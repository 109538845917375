import { useQuery } from '@tanstack/react-query';
import { GetListUserEntity, UserWalletManageService } from 'services/openapi';

import { UserServiceQueryKeys } from '../constants';
import { isEmpty } from 'lodash';

type GetListUserDTO = {
  searchString?: string;
  page: string;
  pageSize: string;
  isActive?: boolean;
  role?: 'Agency' | 'Leader' | 'SpVisor';
  leader?: string;
  searchDetailContent?: string;
};

export const useGetUsersListQuery = (variables: GetListUserDTO) => {
  const queryKey = [UserServiceQueryKeys.GET_USERS_LIST, variables];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<GetListUserEntity, Error>({
    queryKey,
    queryFn: () =>
      UserWalletManageService.userManageControllerGetListUser(
        variables.searchString,
        variables.page,
        variables.pageSize,
        variables.isActive,
        variables.role,
        variables.leader,
        variables.searchDetailContent,
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: !isEmpty(variables)
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};

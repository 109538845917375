// export * from './client';
// export * from './cookies';
// export * from './env';
// export * from './menu';
// export * from './roles';

// App localstorage keys
export const LOCALE_KEY = 'locale';
export const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY';

// App constants default
export const REFRESH_TOKEN_BEFORE = 2 * 60000;
export const DEFAULT_ORDER = 'DESC';
export const MINUTE = 60000;

export const DEFAULT_PAGE_INDEX = 0;
export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;

// App format default
export const numberFormat = new Intl.NumberFormat('vi-VN');

export const DEFAULT_COUNTDOWN_OTP = 60;
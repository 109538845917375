import { Icon, IInputProps } from 'modules';
import React, { Fragment, useState } from 'react';

export const InputPinCode = (props: IInputProps): JSX.Element => {
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  return (
    <Fragment>
      <input
        name={props.name}
        className="InputPinCode"
        placeholder={props.placeholder}
        type={isVisiblePassword ? 'text' : 'password'}
        inputMode={isVisiblePassword ? 'text' : 'numeric'}
        pattern="[0-9]*"
        maxLength={6}
        value={props?.value || ''}
        onFocus={() => props.onTouched()}
        onChange={(e) => props.onChange(e.target.value)}
      />

      {props.value ? (
        <div className="InputPassword_ToggleVisible" onClick={() => setIsVisiblePassword((state) => !state)}>
          {isVisiblePassword ? <Icon.Invisible /> : <Icon.Visible />}
        </div>
      ) : null}
    </Fragment>
  );
};

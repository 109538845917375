import { useMutation } from '@tanstack/react-query';
import { UserWalletManageService } from 'services/openapi';

type UpdateUserMutationVariables = {
  userId: string;
  phoneNumber: string;
  fullName: string;
  email: string;
  citizenIdentityCardNo: string;
  role: 'Agency' | 'Leader' | 'SpVisor';
  compId: string;
  agencyId: string;
  isActive: boolean;
};

export const useUpdateUserMutation = () => {
  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    any,
    Error,
    UpdateUserMutationVariables
  >({
    mutationFn: (variables: UpdateUserMutationVariables) => {
      return UserWalletManageService.userManageControllerUpdateUserInfo(variables);
    },
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RequestWithdrawQueryKeys } from '../constants';
import { WithdrawRequestService, RejectWithdrawRequestDto, RejectWithdrawRequestEntity } from 'services/openapi';

export type RejectRequestWithdrawType = {
  id: string;
  variables: RejectWithdrawRequestDto;
}
export const useRejectRequestWithdrawMutation = () => {
  const queryClient = useQueryClient();

  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    RejectWithdrawRequestEntity,
    Error,
    RejectRequestWithdrawType
  >({
    mutationFn: (request: RejectRequestWithdrawType) => {
      return WithdrawRequestService.withdrawRequestControllerReject(request.id, request.variables);
    },
    onSuccess: () => Promise.all([queryClient.invalidateQueries([RequestWithdrawQueryKeys.REJECT_REQUEST_WITHDRAW])]),
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

export enum Roles {
  ADMIN = "Admin",
  AGENCY = "Agency",
  LEADER = "Leader",
  SPVISOR = "SpVisor",
}

export const RolesName = {
  [Roles.ADMIN]: "Quản trị viên",
  [Roles.AGENCY]: "Đại lý",
  [Roles.LEADER]: "Trưởng nhóm",
  [Roles.SPVISOR]: "Nhân viên đối soát",
};

// export enum Roles {
//   ADMIN = 'Quản trị viên',
//   AGENCY = 'Đại lý',
//   LEADER = 'Trưởng nhóm',
//   SPVISOR = 'Nhân viên đối soát',
// }

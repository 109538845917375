import { flexRender, getCoreRowModel, RowData, useReactTable } from "@tanstack/react-table";
import { DebouncedInput } from "components/input";
import Loading from "components/Loading";
import { Button, Icon, Message } from "modules";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { IndeterminateCheckbox } from "./Components";
import { initFilterFunction, itemsPerPageOptions } from "./config";
import type { TableListDataProps } from "./types";
import { useColumnsDefine, usePagination } from "./utils";
import { DEFAULT_PAGE_INDEX } from "configs";
import { useNavigate } from "react-router-dom";

const TableListData = <TData extends RowData>(props: TableListDataProps<TData>) => {
  const { columns: columnDefined, useQuery, variables, refetchDataCurrentPage, setVariables, convertVariables, filterKey, callBackData, ...tProps } = props;

  const [rowSelection, setRowSelection] = useState({});

  const { pagination, setPagination } = usePagination({
    initPageIndex: variables?.pageIndex,
    initPageSize: variables?.pageSize,
  });

  const [fetchData, setFetchData] = useState<number>(0);

  const navigate = useNavigate()

  const memoVariables = useMemo(
    () => {
      const filter = {
        ...(convertVariables ? convertVariables(variables) : variables),
        ...(filterKey && { [filterKey as unknown as string]: variables?.searchDetailContent }),
        ...pagination,
        fetchData: fetchData
      }
      const lstMap = Object.entries(filter).map(([key, value]) => {
        return `${key}=${value?.toString()?.replace('+', '%2B') ?? ''}`
      });
      if (fetchData != 0) {
        navigate(`${navigate.name}?${lstMap.join('&')}`)
        return initFilterFunction(filter)()
      }
      else {
        return {}
      }
    },
    [fetchData]
  );

  const { isFetching, data = "", refetch } = useQuery?.({ ...memoVariables })

  useEffect(() => {
    if (!variables) return
    setPagination({
      pageIndex: fetchData == 0 ? variables?.pageIndex : DEFAULT_PAGE_INDEX,
      pageSize: pagination.pageSize,
    })
    setFetchData((prev) => prev + 1)
  }, [variables]);

  useEffect(() => {
    if (!refetchDataCurrentPage) return
    setFetchData((prev) => prev + 1)
  }, [refetchDataCurrentPage]);

  useEffect(() => {
    if (data == "") return
    // return 
    if (callBackData) {
      callBackData(data)
    }
  }, [data]);

  // const memoData = useMemo(() => (Array.isArray(data) ? data : data?.data || data?.[tProps?.dataQueryKey] || []), [data]);
  const memoData = useMemo(() => (Array.isArray(data) ? data : tProps?.dataQueryKey ? data?.data?.[tProps?.dataQueryKey] : data?.data) ?? [], [data]);

  const { columns, ...rest } = useColumnsDefine({ columns: columnDefined, ...tProps });

  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: memoData,
    columns: memoColumns,
    state: {
      rowSelection,
      pagination,
    },
    pageCount: Math.ceil(data.total / pagination.pageSize),
    enableRowSelection: tProps?.canSelectable ? (row) => Boolean(tProps?.canSelectable?.(row.original)) : rest?.selectable,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: tProps?.debugTable,
  });

  const handleChange = useCallback(() => {
    const selectedRows = table.getSelectedRowModel().flatRows.map((row) => row.original);

    tProps.onSelectedRowsCallback?.(selectedRows);
  }, [table.getSelectedRowModel()]);

  useEffect(() => {
    handleChange();
  }, [handleChange]);

  return (
    <Loading isLoading={isFetching}>
      <div className="Table">
        <div className="Header row justify-content-end align-items-center g10" style={{ position: "sticky", top: "0", left: "-10px", zIndex: 1 }}>
          {typeof tProps?.customHeader === "function" ? tProps?.customHeader(data) : <></>}

          {filterKey ? (
            <div className="TableFilterInputText">
              <DebouncedInput
                value={variables?.searchDetailContent ?? ""}
                onChange={(value) => {
                  if (fetchData > 0) {
                    setVariables && setVariables((prev: any) => ({
                      ...prev,
                      searchDetailContent: String(value)?.trim()
                    }))
                  }
                }
                } placeholder="Nhập từ khóa tìm kiếm"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setVariables && setVariables((prev: any) => ({
                      ...prev,
                      searchDetailContent: String(e.target.value)?.trim()
                    }))
                  }
                }} />
            </div>
          ) : (
            <></>
          )}

          {typeof tProps?.customHeaderBtnGroup === "function" ? tProps?.customHeaderBtnGroup(data) : <></>}

          {tProps?.hasRefetch && (
            <Button type="button" buttonType="info-outline" className="mr0 ml5 mt24 mb24 pdr10 pdl10" onClick={() => refetch?.()} icon={() => <Icon.Reload />} label={false} />
          )}
        </div>

        <table>
          <thead className="Table__Head">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody className="Table__Body">
            {typeof tProps?.customTopRow === "function" ? tProps?.customTopRow(data) : <></>}

            {memoData.length ? (
              table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell, index) => {
                      return <td key={index}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={20}>
                  <Message type="emptyData" message="Không tìm thấy dữ liệu" />
                </td>
              </tr>
            )}
            {typeof tProps?.customBottomRow === "function" ? tProps?.customBottomRow(data) : <></>}
          </tbody>

          {memoData.length && rest?.selectable ? (
            <tfoot>
              <tr>
                <td>
                  <IndeterminateCheckbox
                    {...{
                      checked: table.getIsAllPageRowsSelected(),
                      indeterminate: table.getIsSomePageRowsSelected(),
                      onChange: table.getToggleAllPageRowsSelectedHandler(),
                    }}
                  />
                </td>
                <td colSpan={20}>Tổng: ({data?.total || table.getRowModel().rows.length})</td>
              </tr>
            </tfoot>
          ) : (
            <></>
          )}
        </table>

        {memoData.length ? (
          <div className="Footer row pl0 pr0" style={{ position: "sticky", bottom: "0", left: "0", zIndex: 1 }}>
            <div className="PaginationInfo col-6">
              {rest?.selectable ? (
                <>
                  {`${Object.keys(rowSelection).length} / ${data?.total || table.getPreFilteredRowModel().rows.length} đã chọn`}

                  {typeof tProps?.customSelectedFooter === "function" ? tProps?.customSelectedFooter(data) : <></>}
                </>
              ) : (
                `Tổng: ${data?.total || table.getPreFilteredRowModel().rows.length}`
              )}
            </div>

            <div
              className="col-6"
              {...{
                style: {
                  textAlign: "right",
                },
              }}>
              <div className="Pagination">
                <button type="button" className="btnPagination prev" onClick={() => {
                  table.previousPage()
                  setFetchData((prev) => prev + 1)
                }} disabled={!table.getCanPreviousPage()}>
                  <Icon.Prev />
                </button>

                <strong>
                  {!isNaN(table.getPageCount()) ? `${table.getState().pagination.pageIndex + 1} / ${table.getPageCount()}` : table.getState().pagination.pageIndex + 1}
                </strong>

                <button type="button" className="btnPagination next" onClick={() => {
                  table.nextPage()
                  setFetchData((prev) => prev + 1)
                }} disabled={!table.getCanNextPage()}>
                  <Icon.Next />
                </button>
              </div>

              <div className="Pagination">
                <select
                  title="Page size"
                  className="pageSize"
                  name="pageSize"
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                    setFetchData((prev) => prev + 1)
                  }}>
                  {itemsPerPageOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {typeof tProps?.customFooter === "function" ? tProps?.customFooter(data) : <></>}
          </div>
        ) : (
          <></>
        )}
      </div>
    </Loading>
  );
};

export default TableListData;

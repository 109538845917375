import { matchRoutes, useLocation } from 'react-router-dom';
import { routesDefined } from 'routes';
import { MainRouteKeys } from 'routes/enums';
import { MainRoutesConfig } from 'routes/main/config';

export const useCurrentPath = () => {
  const location = useLocation();

  const currentPage = MainRoutesConfig?.[location.pathname as MainRouteKeys] || null;

  const matchedRoutes = matchRoutes(routesDefined, location.pathname);

  return {
    paths: matchedRoutes || [],
    currentPage,
  };
};

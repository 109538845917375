const defaultState = false;

export const SET_DETAIL_ANNOUNCEMENT = 'SET_DETAIL_ANNOUNCEMENT';

export const detailAnnouncementReducer = (state = defaultState, action: any) => {
  const { type, data, error } = action;
  if (type === SET_DETAIL_ANNOUNCEMENT) {
    if (data) return data;
    if (error) return { error };
  }
  return state;
};

import { useMutation } from '@tanstack/react-query';
import { LogoutUserEntity, UserWalletAuthService } from 'services/openapi';

export const useLogoutMutation = () => {
  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<LogoutUserEntity, Error>({
    mutationFn: () => {
      return UserWalletAuthService.authControllerLogout();
    },
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

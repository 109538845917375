import React, { type HTMLProps, useEffect } from 'react';

type Props = {
  indeterminate?: boolean;
  className?: string;
} & HTMLProps<HTMLInputElement>;

const IndeterminateCheckbox = (props: Props) => {
  const { indeterminate, className = '', ...rest } = props;

  const ref = React.useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <div className="checkbox-compnt">
      <input type="checkbox" ref={ref} className={className + ' checkbox-compnt__icon'} {...rest} />
    </div>
  );
};

export default IndeterminateCheckbox;

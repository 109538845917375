const defaultState = null;

export const GET_MAINTENANCE_CONFIG = 'GET_MAINTENANCE_CONFIG';
export const GET_MAINTENANCE_CONFIG_ERROR = 'GET_MAINTENANCE_CONFIG_ERROR';
export const maintenanceConfigReducer = (state = defaultState, action: any) => {
  const { type } = action;
  if (type === GET_MAINTENANCE_CONFIG) return action.data;
  if (type === GET_MAINTENANCE_CONFIG_ERROR) return defaultState;
  return state;
};

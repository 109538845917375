/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateBankAccountDto } from '../models/CreateBankAccountDto';
import type { CreateBankAccountEntity } from '../models/CreateBankAccountEntity';
import type { GetListBankAccountEntity } from '../models/GetListBankAccountEntity';
import type { GetListBankEntity } from '../models/GetListBankEntity';
import type { RemoveBankAccountEntity } from '../models/RemoveBankAccountEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BankAccountService {

    /**
     * List bank account
     * @returns GetListBankAccountEntity 
     * @throws ApiError
     */
    public static bankAccountControllerGetListBankAccount(): CancelablePromise<GetListBankAccountEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bank-accounts',
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns CreateBankAccountEntity 
     * @throws ApiError
     */
    public static bankAccountControllerCreatePinCode(
requestBody: CreateBankAccountDto,
): CancelablePromise<CreateBankAccountEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bank-accounts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * List banks
     * @returns GetListBankEntity 
     * @throws ApiError
     */
    public static bankAccountControllerGetListBanks(): CancelablePromise<GetListBankEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bank-accounts/banks',
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param bankAccountId 
     * @returns RemoveBankAccountEntity 
     * @throws ApiError
     */
    public static bankAccountControllerRemoveBankAccount(
bankAccountId: string,
): CancelablePromise<RemoveBankAccountEntity> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/bank-accounts/{bankAccountId}',
            path: {
                'bankAccountId': bankAccountId,
            },
        });
    }

}

import { useMutation } from '@tanstack/react-query';
import { SendOtpByEmailDto, SendOtpByEmailEntity, UserWalletAuthService } from 'services/openapi';

export const useAuthControllerSendOtpByEmail = () => {
  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    SendOtpByEmailEntity,
    Error,
    SendOtpByEmailDto
  >({
    mutationFn: (variables: SendOtpByEmailDto) => {
      return UserWalletAuthService.authControllerSendOtpByEmail(variables);
    },
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

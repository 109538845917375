import { useMutation } from '@tanstack/react-query';
import { MainRouteKeys } from 'routes/enums';
import { CreatePinCodeDto, CreatePinCodeEntity, UserWalletAuthService } from 'services/openapi';

export const useCreatePinCodeMutation = () => {
  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    CreatePinCodeEntity,
    Error,
    CreatePinCodeDto
  >({
    mutationFn: (variables: CreatePinCodeDto) => {
      return UserWalletAuthService.authControllerCreatePinCode(variables);
    },
    onSuccess: () => {
      window.location.replace(MainRouteKeys.Root);
    },
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

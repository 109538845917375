import Cookies from 'universal-cookie';

const cookies = new Cookies();

const DefaultConfigs = {
  ENV: 'local',

  // General information
  PUBLIC_URL: 'http://localhost:3000',
  NUMBER_DECIMAL_DISPLAY: 8,
  ADMIN_EMAIL: '2mau.dev@gmail.com',

  // RESTful APIs
  URL_API_MAIN_CLIENT_SIDE: process.env.REACT_APP_URL_API_MAIN_CLIENT_SIDE,
  URL_SOCKET: 'https://notifier.vonic.vn',
  URL_SOCKET_APP_ID: 'LgpeFzIxtVfgtJ3L6',
  URL_API_MAIN_STORAGE_SIDE: 'https://onetouchwallet.blob.core.windows.net',

  GOOGLE_RECAPTCHA_KEY: '6Le0ev4UAAAAAEniDJf_d2ZaXPwv1AmMtKeS-lwA',
  TRADE_REAL_COIN_CODE: 'USD',
  TRADE_DEMO_COIN_CODE: 'DEMO',
  PASSWORD_SUPERADMIN: '11223344',
  BSC_SCAN: 'https://testnet.bscscan.com/tx/',
  TRON_SCAN: 'https://nile.tronscan.org/#/transaction/',
};

export function getEnv(
  key:
    | 'ENV'

    // General information
    | 'PUBLIC_URL'
    | 'NUMBER_DECIMAL_DISPLAY'
    | 'ADMIN_EMAIL'

    // RESTful APIs
    | 'URL_API_MAIN_CLIENT_SIDE'
    | 'URL_SOCKET'
    | 'URL_SOCKET_APP_ID'
    | 'URL_API_MAIN_STORAGE_SIDE'
    | 'GOOGLE_RECAPTCHA_KEY'
    | 'TRADE_REAL_COIN_CODE'
    | 'TRADE_DEMO_COIN_CODE'
    | 'PASSWORD_SUPERADMIN'
    | 'BSC_SCAN'
    | 'TRON_SCAN',
): string {
  return cookies.get(key) || DefaultConfigs[key];
}

export const isDev = getEnv('ENV') !== 'production';

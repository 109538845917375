import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { MainRouteKeys } from 'routes/enums';
import { MainRoutesConfig } from 'routes/main/config';
import { useCurrentPath } from 'utils/hooks';

const Breadcrumbs = (): JSX.Element => {
  const { paths } = useCurrentPath();

  return (
    <nav aria-label="breadcrumb" className="Breadcrumbs">
      <ul>
        {paths.map((item, index) => {
          const getRouteOptions = MainRoutesConfig?.[item.pathname as MainRouteKeys];
          return (
            <li key={item.pathname} className="breadcrumb-item">
              {index === paths.length - 1 ? (
                <span className="breadcrumb-item active" aria-current="page">
                  <BreadcrumbDetails getRouteOptions={String(getRouteOptions?.name)} />
                </span>
              ) : (
                <Link to={item.pathname}>{getRouteOptions.name}</Link>
              )}
              {index < paths.length - 1 && <span className="separator">{'>'}</span>}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

function BreadcrumbDetails({ getRouteOptions }: { getRouteOptions: string }) {
  const { orderCode, transactionCode } = useParams();

  if (orderCode) {
    return (
      <>
        <Link to={MainRoutesConfig[MainRouteKeys.PayBillElectric].path}>
          {MainRoutesConfig[MainRouteKeys.PayBillElectric].name}
        </Link>
        <span className="separator">{'>'}</span>
        {orderCode}
      </>
    );
  }

  if (transactionCode) {
    return (
      <>
        <Link to={MainRoutesConfig[MainRouteKeys.TransactionBills].path}>
          {MainRoutesConfig[MainRouteKeys.TransactionBills].name}
        </Link>
        <span className="separator">{'>'}</span>
        {transactionCode}
      </>
    );
  }

  return <span>{getRouteOptions}</span>;
}

export default Breadcrumbs;

import { Loading } from 'components';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

const Error = (): JSX.Element => {
  return (
    <Suspense fallback={<Loading />}>
      <Outlet />
    </Suspense>
  );
};

export default Error;

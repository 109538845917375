import { useQuery } from '@tanstack/react-query';
import { PayBillOrderEntityReturn, UserWalletServicesService } from 'services/openapi';

import { ElectricQueryKeys } from './constants';
import { isEmpty } from 'lodash';

type GetListTransPayDTO = {
  orderCode: string;
  searchDetailContent?: string;
  page: string;
  pageSize: string;
};

export const useGetElectricBillDetailsQuery = (variables: GetListTransPayDTO) => {
  const queryKey = [ElectricQueryKeys.GET_ELECTRIC_BILLS_DETAILS, variables];
  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    PayBillOrderEntityReturn,
    Error
  >({
    queryKey,
    queryFn: () =>
      UserWalletServicesService.servicesControllerGetOneUser(
        variables.orderCode?.trim(),
        variables.searchDetailContent?.trim(),
        variables.page,
        variables.pageSize,
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    // enabled: false
    enabled: !isEmpty(variables) && !isEmpty(variables?.orderCode)
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};

import { useQuery } from '@tanstack/react-query';
import { GetListLeadersEntity, UserWalletManageService } from 'services/openapi';

import { UserServiceQueryKeys } from '../constants';

export const useGetLeadersListQuery = () => {
  const queryKey = [UserServiceQueryKeys.GET_LEADERS_LIST];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    GetListLeadersEntity,
    Error
  >({
    queryKey,
    queryFn: () => UserWalletManageService.userManageControllerGetListLeaders(),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: true,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data,
    isError,
    queryKey,
    refetch,
  };
};

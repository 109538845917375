import { useQuery } from '@tanstack/react-query';
import { AgencyEntity, UserWalletCompanyInfoService } from 'services/openapi';

import { AgencyQueryKeys } from './constants';

export const useGetAgenciesQuery = () => {
  const queryKey = [AgencyQueryKeys.GET_AGENCY_INFO];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<AgencyEntity[], Error>({
    queryKey,
    queryFn: () => UserWalletCompanyInfoService.companyControllerAgencyFindAll(),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data,
    isError,
    queryKey,
    refetch,
  };
};

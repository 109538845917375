import { useMutation } from '@tanstack/react-query';
import { UserWalletManageService } from 'services/openapi';

type AddPointMutationVariables = {
  toUserId: string;
  amount: number;
  reason: string;
};

export const useAddPointMutation = () => {
  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    any,
    Error,
    AddPointMutationVariables
  >({
    mutationFn: (variables: AddPointMutationVariables) => {
      return UserWalletManageService.userManageControllerAddPointToUser(variables);
    },
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

import { useQuery } from '@tanstack/react-query';
import { BankAccountService, GetListBankEntity } from 'services/openapi';

import { BankAccountServiceQueryKeys } from '../constants';

export const useGetListBanksQuery = () => {
  const queryKey = [BankAccountServiceQueryKeys.GET_LIST_BANKS];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<GetListBankEntity, Error>({
    queryKey,
    queryFn: () => BankAccountService.bankAccountControllerGetListBanks(),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: Infinity,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data,
    isError,
    queryKey,
    refetch,
  };
};

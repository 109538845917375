import { combineReducers } from 'redux';

import { coinConversionRateReducer } from '../services/bank/coinConversionRate.reducer';
import { maintenanceConfigReducer } from '../services/config/maintenance.reduce';
import { withdrawConfigReducer } from '../services/config/withdraw.reduce';
import { countriesReducer } from '../services/main/contries.reducer';
import { mainReducer } from '../services/main/main.reducer';
import { openedOrdersReducer } from '../services/orderOld/openedOrders.reducer';
import { coinsReducer } from '../services/trade/coins.reducer';
import { resultPannelDropletsReducer } from '../services/trade/resultPannelDroplets.reducer';
import { symbolsReducer } from '../services/trade/symbols.reducer';
import { tradeHistoriesReducer } from '../services/trade/tradeHistories.reducer';
import { tradeLastResultReducer } from '../services/trade/tradeLastResult.reducer';
import { userReducer } from '../services/user/user.reducer';
import { userExternalWalletsReducer } from '../services/user/userExternalWallets.reducer';
import { userInternalWalletsReducer } from '../services/user/userInternalWallets.reducer';
import { userWalletBalancesReducer } from '../services/user/userWalletBalances.reducer';
import { detailAnnouncementReducer } from './../services/config/editAnnouncement.reduce';

export const reducers = combineReducers({
  main: mainReducer,

  user: userReducer,
  userExternalWallets: userExternalWalletsReducer,
  userInternalWallets: userInternalWalletsReducer,
  userWalletBalances: userWalletBalancesReducer,

  countries: countriesReducer,

  symbols: symbolsReducer,
  coins: coinsReducer,
  coinConversionRate: coinConversionRateReducer,

  tradeHistories: tradeHistoriesReducer,
  tradeLastResult: tradeLastResultReducer,

  resultPannelDroplets: resultPannelDropletsReducer,

  openedOrders: openedOrdersReducer,

  withdrawConfig: withdrawConfigReducer,
  maintenanceConfig: maintenanceConfigReducer,

  detailAnnouncement: detailAnnouncementReducer,
});

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateProfileUserDto, UpdateProfileUserEntity, UserWalletAuthService } from 'services/openapi';

import { UserServiceQueryKeys } from '../constants';

export const useUpdateProfileUserMutation = () => {
  const queryClient = useQueryClient();

  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    UpdateProfileUserEntity,
    Error,
    UpdateProfileUserDto
  >({
    mutationFn: (variables: UpdateProfileUserDto) => {
      return UserWalletAuthService.authControllerUpdateProfile(variables);
    },
    onSuccess: () => Promise.all([queryClient.invalidateQueries([UserServiceQueryKeys.GET_USER_PROFILE])]),
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

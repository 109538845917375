import { useMutation } from '@tanstack/react-query';
import { UserWalletManageService } from 'services/openapi';

type RemoveUserMutationVariables = {
  userId: string;
};

export const useRemoveUserMutation = () => {
  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    any,
    Error,
    RemoveUserMutationVariables
  >({
    mutationFn: (variables: RemoveUserMutationVariables) => {
      return UserWalletManageService.userManageControllerRemoveUser(variables.userId);
    },
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

import { Store } from 'redux';

import { store } from '../../store';
import { RequestMainService } from '../request';
import { SET_COUNTRIES } from './contries.reducer';
import { INCREASE_APP_LIFE_CYCLE, SET_USER_PAGE_INITIALIZE_STATUS } from './main.reducer';

export class MainService {
  static async initializeClient() {
    return Promise.all([
      // this.getCountries(store),
      this.initializeTime(store),
    ]);
  }

  static async initialUserPage() {
    const { userPageInitializeStatus } = store.getState().main;

    if (userPageInitializeStatus !== 'pending') return;

    store.dispatch({
      type: SET_USER_PAGE_INITIALIZE_STATUS,
      status: 'processing',
    });

    // await Promise.all([
    //     UserService.auth(store),
    //     TradeService.getSymbols(store),
    //     TradeService.getCoins(store),
    //     BankService.getCoinConversionRate(store),
    //     ConfigService.getServerConfigWithdraw(store),
    //     ConfigService.getServerConfigMaintenance(store),
    // ]);

    store.dispatch({
      type: SET_USER_PAGE_INITIALIZE_STATUS,
      status: 'completed',
    });
  }

  static async getCountries(store: Store) {
    return RequestMainService.get(`/country`)
      .then(({ result }) => {
        const data = result;
        store.dispatch({
          type: SET_COUNTRIES,
          data: {
            count: data.length,
            data: data,
          },
        });
      })
      .catch((res) =>
        store.dispatch({
          type: SET_COUNTRIES,
          error: res.error,
        }),
      );
  }

  static async forceUpdateApp() {
    return store.dispatch({ type: INCREASE_APP_LIFE_CYCLE });
  }

  static async initializeTime(store: Store) {
    let handleInterval: any;
    const handleClearInterval = () => clearInterval(handleInterval);

    // TradeService.getInitTime()
    //     .then((time) => {
    //         store.dispatch({
    //             type: SET_TIME,
    //             time: 60 - new Date(time + 100).getSeconds(),
    //             serverTime: time + 100,
    //         });
    //         handleInterval = setInterval(
    //             () => store.dispatch({ type: UPDATE_TIME }),
    //             1000
    //         );
    //     })
    //     .catch((err) =>
    //         CreateAlert({ message: err.message, type: "danger" })
    //     );

    // setInterval(() => {
    //     TradeService.getInitTime()
    //         .then((time) => {
    //             store.dispatch({
    //                 type: SET_TIME,
    //                 time: 60 - new Date(time + 100).getSeconds(),
    //                 serverTime: time + 100,
    //             });
    //             handleClearInterval();
    //             handleInterval = setInterval(
    //                 () => store.dispatch({ type: UPDATE_TIME }),
    //                 1000
    //             );
    //         })
    //         .catch((err) =>
    //             CreateAlert({ message: err.message, type: "danger" })
    //         );
    // }, 30000);
  }
}

export enum RequestWithdrawQueryKeys {
  GET_LIST_REQUEST_WITHDRAW_OWN = 'GET_LIST_REQUEST_WITHDRAW_OWN',
  GET_LIST_REQUEST_WITHDRAW = 'GET_LIST_REQUEST_WITHDRAW',
  GET_LIST_ACCOUNT_BANKS = 'GET_LIST_ACCOUNT_BANKS',
  CREATE_REQUEST_WITHDRAW = 'CREATE_REQUEST_WITHDRAW',
  APPROVE_REQUEST_WITHDRAW = 'APPROVE_REQUEST_WITHDRAW',
  REJECT_REQUEST_WITHDRAW = 'REJECT_REQUEST_WITHDRAW',
  EXPORT_REQUEST_WITHDRAW = 'EXPORT_REQUEST_WITHDRAW',
}


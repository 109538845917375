import React, { useState } from 'react';

type Props = {
  activeTab?: string;
  data: string[];
  onChange: (tab: string) => void;
};

const NavTab = (props: Props): JSX.Element => {
  const { onChange, data, activeTab } = props;

  const [currentTab, setCurrentTab] = useState<string>(activeTab || data[0] || '');

  const handleTabChange = (tab: string) => {
    setCurrentTab(tab);
    onChange(tab);
  };

  return (
    <div className="TabLinks">
      {data?.map((tab) => (
        <a
          key={tab as string}
          className={currentTab === tab ? 'active' : ''}
          onClick={() => handleTabChange(tab as string)}
        >
          {tab}
        </a>
      ))}
    </div>
  );
};

export default NavTab;

import { useMutation } from '@tanstack/react-query';
import { ResetPasswordByUserEmailDto, ResetPasswordByEmailEntity, UserWalletAuthService } from 'services/openapi';

export const useAuthControllerResetPinCodeByEmail = () => {
  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
  ResetPasswordByEmailEntity,
    Error,
    ResetPasswordByUserEmailDto
  >({
    mutationFn: (variables: ResetPasswordByUserEmailDto) => {
      return UserWalletAuthService.authControllerResetPinCodeByEmail(variables);
    },
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

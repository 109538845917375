import React, { useState } from 'react';

import Item from './Item';

export type ItemTypes = {
  title?: string;
  path: string;
  icon: React.ReactNode;
  childs?: ItemTypes[];
};

type Props = {
  onClickCallback?: () => void;
  data: ItemTypes & {
    childs?: ItemTypes[];
  };
};

const MenuItems: React.FC<Props> = ({ data, onClickCallback }) => {
  const { childs } = data;

  const haveChild = childs && childs.length > 0;

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {haveChild ? (
        <div className={`dropdown ${isOpen ? 'open' : ''}`}>
          <Item
            key={data.path}
            data={data}
            onClickCallback={() => {
              handleToggle();
              // onClickCallback?.();
            }}
            haveChild
          />

          <div className="dropdown-menu">
            {childs.map((child: ItemTypes) => {
              return <Item key={child.path} data={child} onClickCallback={onClickCallback} />;
            })}
          </div>
        </div>
      ) : (
        <Item key={data.path} data={data} onClickCallback={onClickCallback} />
      )}
    </>
  );
};

export default MenuItems;

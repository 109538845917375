import { ECookieVariable } from 'configs/cookies';
import { ObjectUtils } from 'modules';
import { Store } from 'redux';
import { store } from 'store';
import { EOrderOption, EOrderStatus } from 'types';

import { CookieService } from '../cookie';
import { MainService } from '../main';
import { RequestMainService } from '../request';
import { SocketService } from '../socket';
import { SET_COINS } from './coins.reducer';
import { SET_SYMBOLS } from './symbols.reducer';

export class TradeService {
  static async getCoins(store: Store) {
    return RequestMainService.get(`/coin`)
      .then(({ result }) => store.dispatch({ type: SET_COINS, data: { count: result.length, data: result } }))
      .catch((res) => store.dispatch({ type: SET_COINS, error: res.error }));
  }

  static getCoinIdFromCoinCode(code: string, store: Store) {
    const coins = ObjectUtils.getIn(store.getState().coins, 'data', []);
    return ObjectUtils.getIn(
      coins.find((item: any) => item.code === code),
      'coinId',
      '',
    );
  }

  static getCoinImageSrc(coinCode: string) {
    if (coinCode === 'USDT') return '/assets/images/coins/usdt.png';
    if (coinCode === 'ETHEREUM') return '/assets/images/coins/eth.png';
    if (coinCode === 'ERA20') return '/assets/images/coins/era20.png';
    if (coinCode === 'GBC') return '/assets/images/coins/gbc.png';
    if (coinCode === 'USD') return '/assets/images/coins/usdp.png';
    return '/favicon.ico';
  }

  static async getSymbols(store: Store) {
    return RequestMainService.get(`/symbol`)
      .then(({ result }) => {
        const symbols = result.filter((v: any) => !['GBCBTC', 'ETHBTC', 'ETHUSDT'].includes(v.name));

        const currentSymbolName = decodeURIComponent(CookieService.get(ECookieVariable.USER_SYMBOL_ACTIVE_NAME));

        const symbolActive = symbols.find((v: any) => v.name === currentSymbolName) || symbols[0];
        CookieService.set(ECookieVariable.USER_SYMBOL_ACTIVE_NAME, ObjectUtils.getIn(symbolActive, 'name'));
        CookieService.set(ECookieVariable.USER_SYMBOL_ACTIVE_ID, ObjectUtils.getIn(symbolActive, 'symbolId'));

        store.dispatch({
          type: SET_SYMBOLS,
          data: {
            count: symbols.length,
            data: symbols,
          },
        });
      })
      .catch((res) =>
        store.dispatch({
          type: SET_SYMBOLS,
          error: res.error,
        }),
      );
  }

  static getSymbolIdFromSymbolName(name: string, store: Store) {
    const symbols = ObjectUtils.getIn(store.getState().symbols, 'data', []);
    return ObjectUtils.getIn(
      symbols.find((item: any) => item.name === name),
      'symbolId',
      '',
    );
  }

  static async getTradeData(limit = 2) {
    return RequestMainService.get(`/trading-view/apex-history`, {
      symbol: this.getSymbolActiveName(),
      limit,
    })
      .then((data) =>
        data.map((item: any) => {
          item[0] = item[0] * 1000;
          return item;
        }),
      )
      .catch(() => false);
  }

  static getSymbolActiveName() {
    return decodeURIComponent(CookieService.get(ECookieVariable.USER_SYMBOL_ACTIVE_NAME));
  }

  static setSymbolActive(symbol: any) {
    CookieService.set(ECookieVariable.USER_SYMBOL_ACTIVE_NAME, symbol.name);
    CookieService.set(ECookieVariable.USER_SYMBOL_ACTIVE_ID, symbol.symbolId);
    MainService.forceUpdateApp();
    SocketService.initialize(true);
  }

  static async getHistory() {
    return RequestMainService.get(`/order`, {
      page: 1,
      pageSize: 100,
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }

  // static async getInitTime() {
  //     return RequestMainService.get(`/time`, {}, true)
  //         .then((res) => {
  //             return new Date(res.result).getTime() + res._responseTime
  //         })
  // }

  static async getSymbol(store: Store) {
    return RequestMainService.get(`/symbol`)
      .then(({ result }) => store.dispatch({ type: SET_SYMBOLS, data: result }))
      .catch((res) => store.dispatch({ type: SET_SYMBOLS, data: { error: res.error } }));
  }

  static setSymbolActiveName(name: string) {
    CookieService.set(ECookieVariable.USER_SYMBOL_ACTIVE_NAME, name);
    window.location.reload();
  }

  static async getMarketInfo(coinId: any) {
    return RequestMainService.get(
      `/market-info/${CookieService.get(ECookieVariable.USER_SYMBOL_ACTIVE_ID)}/${coinId}`,
    ).then(({ result }) => result);
  }

  static async getLastResult() {
    return RequestMainService.get(`/trading-view/apex-history`, {
      symbol: this.getSymbolActiveName(),
      limit: 210,
    }).then((data) =>
      data.map((item: any) => {
        item[0] = item[0] * 1000;
        return item;
      }),
    );
  }

  static async getTradingResults() {
    return RequestMainService.get(`/trading-results`, {
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    });
  }

  static exportReportFromOrders(orders: any[] = []) {
    const winOrders = orders.filter((item: any) => item.status === EOrderStatus.WIN);
    const winProfit: number = winOrders.reduce((output: any, item: any) => output + item.profit, 0);
    const winRevenue: number = winOrders.reduce((output: any, item: any) => output + (item.profit - item.amount), 0);

    const loseOrders = orders.filter((item: any) => item.status === EOrderStatus.LOSE);
    const loseProfit: number = loseOrders.reduce((output: any, item: any) => output + item.amount, 0);

    const type = winProfit > loseProfit ? 'win' : winProfit < loseProfit ? 'lose' : 'draw';

    return {
      winOrders,
      winProfit: +winProfit.toFixed(3),
      winRevenue: +winRevenue.toFixed(3),
      loseOrders,
      loseProfit: +loseProfit.toFixed(3),
      type,
    };
  }

  static formatRawDataFilterResultCandles(data: any[]) {
    const currentServerTime = new Date(store.getState().main.serverTime).getTime();
    return data.filter((item: any) => new Date(item[0]).getSeconds() === 30 && currentServerTime - item[0] > 31000);
  }

  static async changeTradeResult(option: EOrderOption) {
    return RequestMainService.post(`/game-setting/canlde-result`, {
      symbolId: CookieService.get(ECookieVariable.USER_SYMBOL_ACTIVE_ID),
      option,
    });
  }
}

import { ECookieVariable } from 'configs/cookies';

import dictionary from './lang/dictionary.json';
import { CookieService } from './services/cookie';
import { ELocale } from './types';

export type TLocale = {
  id: number;
  key: ELocale;
  label: string;
  isActive: boolean;
};

export const locales: TLocale[] = [
  {
    id: 1,
    key: ELocale.VIETNAM,
    label: 'Việt Nam',
    isActive: true,
  },
  {
    id: 2,
    key: ELocale.ENGLISH,
    label: 'English',
    isActive: true,
  },
];

export const defaultLocale = locales[0];

export const setLocale = (locale: ELocale): void => {
  CookieService.set(ECookieVariable.USER_LOCALE, locale);
  window.location.reload();
};

export const getLocale = (): TLocale => {
  let locale: TLocale;

  const fromCookie = CookieService.get(ECookieVariable.USER_LOCALE);
  const currentLocale = locales.find((item) => item.key === fromCookie);
  if (!currentLocale) {
    locale = defaultLocale;
    CookieService.set(ECookieVariable.USER_LOCALE, locale.key);
  } else {
    locale = currentLocale;
  }

  return locale;
};

export const getLocaleKey = (): string => getLocale().key;

export const translate = (id: string, values?: any): string => {
  const locale: string = getLocaleKey();
  let sentence: string;

  // @ts-ignore
  if (dictionary[id] && dictionary[id][locale]) {
    // @ts-ignore
    sentence = dictionary[id][locale];
  } else {
    console.warn(`Don't have any messages match with id: "${id}"`);
    return id;
  }

  // Match values
  if (typeof values === 'object') {
    Object.entries(values).map((item) => {
      // @ts-ignore
      sentence = sentence.replace(new RegExp(`{${item[0]}}`, 'g'), item[1]);
      return item;
    });
  }

  return sentence;
};

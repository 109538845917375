import { REFRESH_TOKEN_BEFORE } from 'configs';
import { ECookieVariable } from 'configs/cookies';
import { Roles } from 'configs/roles';
import jwtDecode from 'jwt-decode';
import { AuthRouteKeys } from 'routes/enums';
import { CookieService } from 'services';

export const shouldRefresh = (token: string) =>
  jwtDecode<any>(token).exp * 1000 - new Date().valueOf() <= REFRESH_TOKEN_BEFORE;

export const shouldLogout = (token: string) => {
  try {
    const expireTime = jwtDecode<any>(token).exp * 1000;
    if (expireTime < Date.parse(new Date().toString())) {
      return true;
    }
  } catch (error) {
    return true;
  }

  return false;
};

export const getUserRoles = () => {
  try {
    const accessToken = getAccessToken();
    const userData = (accessToken && jwtDecode<any>(accessToken)) || null;

    if (userData) {
      return Array.isArray(userData?.role) ? userData?.role : [userData?.role];
    }
  } catch (error) {
    return [];
  }
  return [];
};

export const checkValidRole = (roles: string | Roles[]) => {
  const userRoles: Roles[] = getUserRoles();

  if (userRoles.length > 0 && typeof roles === 'string' && roles === '*') {
    return true;
  } else if (Array.isArray(roles) && roles.length > 0 && userRoles.length > 0) {
    const isValid = roles.some((r) => {
      return userRoles.indexOf(r) >= 0;
    });
    return isValid;
  } else {
    return false;
  }
};

export const checkRolesIncludeRoles = (roles: string | Roles[], allRoles: string | Roles[]) => {
  if (allRoles.length > 0 && typeof roles === 'string' && roles === '*') {
    return true;
  } else if (Array.isArray(roles) && roles.length > 0 && allRoles.length > 0) {
    const isValid = roles.some((r) => {
      return allRoles.indexOf(r) >= 0;
    });
    return isValid;
  } else {
    return false;
  }
};

export function getAccessToken() {
  const accessToken = CookieService.get(ECookieVariable.ACCESS_TOKEN_KEY);
  if (!accessToken) {
    return false;
  }

  return accessToken;
}

export const handleLogin = () => {
  CookieService.remove(ECookieVariable.ACCESS_TOKEN_KEY);

  window.location.href = AuthRouteKeys.Root;
};

export const handleLogout = () => {
  CookieService.remove(ECookieVariable.ACCESS_TOKEN_KEY);

  window.location.href = AuthRouteKeys.Root;
};

export const getUserInfo = () => {
  const accessToken = getAccessToken();

  try {
    return accessToken && jwtDecode<any>(accessToken);
  } catch (err) {
    handleLogout();
  }
};

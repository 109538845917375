import { IInputProps } from 'modules';
import React, { FC } from 'react';

export const InputText: FC<IInputProps> = (props) => {
  const { value, onChange, onTouched, onChangeCallback, isReadOnly, onFocus, placeholder, ...rest } = props;

  return (
    <input
      type="text"
      onChange={(e) => {
        if (props.isDisabled) return
        onChange(e.target.value);
        onChangeCallback?.(e.target.value);
      }}
      onBlur={() => setTimeout(() => onTouched(), 500)}
      onFocus={() => {
        if (onFocus) onFocus();
      }}
      value={value || ''}
      placeholder={placeholder || ''}
      readOnly={isReadOnly}
      {...rest}
    />
  );
};

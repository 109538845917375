import { ArrayUtils, Icon, IInputProps } from 'modules';
import React, { FC } from 'react';

export const InputTable: FC<IInputProps> = (props) => {
  const data = Array.isArray(props.value) ? props.value : [];
  const handleChange = (newData: any[]) => props.onChange(newData);

  return (
    <div className="InputTable">
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>Label</th>
            <th>Key</th>
            <th>Actions</th>
          </tr>
        </thead>

        {data.length > 0 ? (
          <tbody>
            {data.map((childValue, childValueKey) => {
              return (
                <tr key={childValueKey}>
                  <td>{childValueKey + 1}</td>
                  <td>
                    <input
                      type="text"
                      key={childValueKey}
                      value={childValue.label}
                      onChange={(e) => {
                        const value = e.target.value;
                        handleChange(
                          ArrayUtils.replaceItemAtIndex(data, childValueKey, { ...childValue, label: value }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      key={childValueKey}
                      value={childValue.key}
                      onChange={(e) => {
                        const value = e.target.value;
                        handleChange(ArrayUtils.replaceItemAtIndex(data, childValueKey, { ...childValue, key: value }));
                      }}
                    />
                  </td>
                  <td>
                    <div
                      className="btnRemove"
                      onClick={() => handleChange(ArrayUtils.removeItemAtIndex(data, childValueKey))}
                    >
                      <Icon.Remove />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : null}
      </table>

      {data.length > 0 ? null : (
        <div className="Message emptyData">
          <Icon.Data />
          No data
        </div>
      )}

      <div className="ctas">
        <div className="btnAddMore" onClick={() => handleChange([...data, { label: '', key: '' }])}>
          Add More
        </div>
      </div>
    </div>
  );
};

import type { PaginationState } from '@tanstack/react-table';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'configs';
import { useMemo, useState } from 'react';

const usePagination = ({
  initPageIndex = DEFAULT_PAGE_INDEX,
  initPageSize = DEFAULT_PAGE_SIZE,
}: {
  initPageIndex?: number,
  initPageSize?: number,
}) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: initPageIndex ?? DEFAULT_PAGE_INDEX,
    pageSize: initPageSize ?? DEFAULT_PAGE_SIZE,
  });

  const pagination = useMemo(
    () => {
      return ({
        pageIndex,
        pageSize,
      })
    },
    [pageIndex, pageSize],
  );

  return { pageIndex, pageSize, pagination, setPagination };
};

export default usePagination;

import { useQuery } from '@tanstack/react-query';
import { CompanyEntity, UserWalletCompanyInfoService } from 'services/openapi';

import { CompanyWalletServiceQueryKeys } from '../constants';

export const useGetCompanyInfoQuery = () => {
  const queryKey = [CompanyWalletServiceQueryKeys.GET_COMPANY_INFO];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<CompanyEntity[], Error>({
    queryKey,
    queryFn: () => UserWalletCompanyInfoService.companyControllerFindAll(),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data,
    isError,
    queryKey,
    refetch,
  };
};

import React from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { ErrorRouteKeys, MainRouteKeys } from '../enums';
import { MainRoutesConfig } from './config';


const mainRoutesDefined: RouteObject = {
  path: MainRoutesConfig[MainRouteKeys.Root].path,
  element: MainRoutesConfig[MainRouteKeys.Root].element,
  children: [
    {
      path: MainRoutesConfig[MainRouteKeys.Personal].path,
      element: MainRoutesConfig[MainRouteKeys.Personal].element,
      children: [
        {
          path: MainRoutesConfig[MainRouteKeys.PersonalInfo].path,
          element: MainRoutesConfig[MainRouteKeys.PersonalInfo].element,
        },
        {
          path: MainRoutesConfig[MainRouteKeys.PersonalChangePassword].path,
          element: MainRoutesConfig[MainRouteKeys.PersonalChangePassword].element,
        },
        {
          path: MainRoutesConfig[MainRouteKeys.PersonalBalance].path,
          element: MainRoutesConfig[MainRouteKeys.PersonalBalance].element,
        },
        {
          path: MainRoutesConfig[MainRouteKeys.BankManagement].path, // New route
          element: MainRoutesConfig[MainRouteKeys.BankManagement].element, // New route
        },
        {
          path: MainRoutesConfig[MainRouteKeys.PersonalLogout].path,
          element: MainRoutesConfig[MainRouteKeys.PersonalLogout].element,
        },
      ],
    },
    {
      path: MainRoutesConfig[MainRouteKeys.RequestWithdraw].path,
      element: MainRoutesConfig[MainRouteKeys.RequestWithdraw].element,
      children: [
        {
          path: MainRoutesConfig[MainRouteKeys.CreateRequestWithdraw].path,
          element: MainRoutesConfig[MainRouteKeys.CreateRequestWithdraw].element,
        },
        {
          path: MainRoutesConfig[MainRouteKeys.RequestWithdrawOwn].path,
          element: MainRoutesConfig[MainRouteKeys.RequestWithdrawOwn].element,
        },
        {
          path: MainRoutesConfig[MainRouteKeys.CheckRequestWithdrawList].path,
          element: MainRoutesConfig[MainRouteKeys.CheckRequestWithdrawList].element,
        },
      ],
    },
    {
      path: MainRoutesConfig[MainRouteKeys.Dashboard].path,
      element: MainRoutesConfig[MainRouteKeys.Dashboard].element,
      children: [
        {
          path: MainRoutesConfig[MainRouteKeys.DashboardReportByAgency].path,
          element: MainRoutesConfig[MainRouteKeys.DashboardReportByAgency].element,
        },
        {
          path: MainRoutesConfig[MainRouteKeys.DashboardReportByPC].path,
          element: MainRoutesConfig[MainRouteKeys.DashboardReportByPC].element,
        },
      ],
    },
    {
      path: MainRoutesConfig[MainRouteKeys.Users].path,
      element: MainRoutesConfig[MainRouteKeys.Users].element,
    },
    {
      path: MainRoutesConfig[MainRouteKeys.UpdateUser].path,
      element: MainRoutesConfig[MainRouteKeys.UpdateUser].element,
    },
    {
      path: MainRoutesConfig[MainRouteKeys.Balance].path,
      element: MainRoutesConfig[MainRouteKeys.Balance].element,
      children: [
        {
          path: MainRoutesConfig[MainRouteKeys.TopUp].path,
          element: MainRoutesConfig[MainRouteKeys.TopUp].element,
        },
        {
          path: MainRoutesConfig[MainRouteKeys.Transfers].path,
          element: MainRoutesConfig[MainRouteKeys.Transfers].element,
        },
      ],
    },
    {
      path: MainRoutesConfig[MainRouteKeys.BalanceManage].path,
      element: MainRoutesConfig[MainRouteKeys.BalanceManage].element,
    },
    {
      path: MainRoutesConfig[MainRouteKeys.PayBill].path,
      element: MainRoutesConfig[MainRouteKeys.PayBill].element,
      children: [
        {
          path: MainRoutesConfig[MainRouteKeys.PayBillElectric].path,
          element: MainRoutesConfig[MainRouteKeys.PayBillElectric].element,
        },
        {
          path: MainRoutesConfig[MainRouteKeys.PayBillElectricDetails].path,
          element: MainRoutesConfig[MainRouteKeys.PayBillElectricDetails].element,
        },
      ],
    },
    {
      path: MainRoutesConfig[MainRouteKeys.Transactions].path,
      element: MainRoutesConfig[MainRouteKeys.Transactions].element,
      children: [
        {
          path: MainRoutesConfig[MainRouteKeys.TransactionBills].path,
          element: MainRoutesConfig[MainRouteKeys.TransactionBills].element,
        },
        {
          path: MainRoutesConfig[MainRouteKeys.TransactionBillDetails].path,
          element: MainRoutesConfig[MainRouteKeys.TransactionBillDetails].element,
        },
        {
          path: MainRoutesConfig[MainRouteKeys.PointTransactions].path,
          element: MainRoutesConfig[MainRouteKeys.PointTransactions].element,
        }
      ],
    },
    {
      path: MainRoutesConfig[MainRouteKeys.Customers].path,
      element: MainRoutesConfig[MainRouteKeys.Customers].element,
    },
    {
      path: MainRoutesConfig[MainRouteKeys.Customers].path,
      element: MainRoutesConfig[MainRouteKeys.Customers].element,
    },
    {
      path: MainRoutesConfig[MainRouteKeys.Orders].path,
      element: MainRoutesConfig[MainRouteKeys.Orders].element,
    },
    {
      path: MainRoutesConfig[MainRouteKeys.TransactionsBank].path,
      element: MainRoutesConfig[MainRouteKeys.TransactionsBank].element,
    },
    {
      path: MainRoutesConfig[MainRouteKeys.AccountBank].path,
      element: MainRoutesConfig[MainRouteKeys.AccountBank].element,
    },
    {
      path: '*',
      element: <Navigate to={ErrorRouteKeys.NotFound} replace />,
    },
  ],
};

export default mainRoutesDefined;

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SearchPhoneNumberEntity } from '../models/SearchPhoneNumberEntity';
import type { TransferToUserDto } from '../models/TransferToUserDto';
import type { TransferToUserEntity } from '../models/TransferToUserEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserWalletTransferService {

    /**
     * @param phoneNumber Phone number ex: start 0xxxxxxxxx or +84xxxxxxxxx
     * @returns SearchPhoneNumberEntity 
     * @throws ApiError
     */
    public static transferControllerSearchPhoneNumber(
phoneNumber: string,
): CancelablePromise<SearchPhoneNumberEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/transfer/check-phone-number',
            query: {
                'phoneNumber': phoneNumber,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns TransferToUserEntity 
     * @throws ApiError
     */
    public static transferControllerTransferToUser(
requestBody: TransferToUserDto,
): CancelablePromise<TransferToUserEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/transfer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Transfer limit exceeded, Pin code wrong,  Can't transfer to yourself, Insufficient balance, Value can't less than or equal 0`,
                401: `Unauthorized`,
                404: `Current user not found, Can't found to user`,
            },
        });
    }

}

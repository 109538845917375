import { useQuery } from '@tanstack/react-query';
import { GetListCustomerInfoEntity, UserWalletCustomerService } from 'services/openapi';

import { CustomersQueryKeys } from './constants';
import { isEmpty } from 'lodash';

type GetListCustomersDTO = {
  customerCode?: string;
  customerName?: string;
  customerPhoneNumber?: string;
  searchDetailContent?: string;
  page: string;
  pageSize: string;
};

export const useExportCustomersListQuery = (variables: GetListCustomersDTO) => {
  const queryKey = [CustomersQueryKeys.GET_CUSTOMERS_LIST, variables];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    GetListCustomerInfoEntity,
    Error
  >({
    queryKey,
    queryFn: () =>
      UserWalletCustomerService.customerManageControllerGetListCustomer(
        variables.customerCode?.trim(),
        variables.customerName?.trim(),
        variables.customerPhoneNumber?.trim(),
        variables.searchDetailContent?.trim(),
        variables.page,
        variables.pageSize,
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: false
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};

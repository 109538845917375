import { useMutation } from '@tanstack/react-query';
import { ECookieVariable } from 'configs/cookies';
import jwtDecode from 'jwt-decode';
import { CookieService } from 'services/cookie';
import { LoginDto, LoginEntity, UserWalletAuthService } from 'services/openapi';
import { formatPhoneNumber } from 'utils/helpers';

const convertVariables = (variables: LoginDto) => {
  const { phoneNumber, ...rest } = variables;

  return {
    phoneNumber: formatPhoneNumber(phoneNumber),
    ...rest,
  };
};

export const useLoginMutation = () => {
  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<LoginEntity, Error, LoginDto>(
    {
      mutationFn: (variables: LoginDto) => {
        return UserWalletAuthService.authControllerLogin(convertVariables(variables));
      },
      onSuccess: (res) => {
        const { token } = res;

        const { exp } = jwtDecode<any>(token);

        CookieService.set(ECookieVariable.ACCESS_TOKEN_KEY, token, {
          expires: new Date(exp * 1000),
        });
      },
    },
  );

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

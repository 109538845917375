import type { ColumnDef, RowData } from "@tanstack/react-table";
import React, { useMemo } from "react";

import { IndeterminateCheckbox } from "../Components";
import { ColumnsDefineProps } from "../types";

const useColumnsDefine = <TData extends RowData, TValue = unknown>(props: ColumnsDefineProps<TData>) => {
  const { columns: columnDefined, isCanSelectable = true, ...rest } = props;

  const columns = useMemo<ColumnDef<TData, TValue>[]>(() => {
    const tableColumnsDefine: ColumnDef<TData, TValue>[] = [];

    if (rest?.selectable) {
      tableColumnsDefine.unshift({
        id: "select",
        header: ({ table }) => {
          return (
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          );
        },
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              // disabled: !row.getCanSelect(),
              disabled: !isCanSelectable,
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      });
    }

    if (rest?.orderColumn) {
      tableColumnsDefine.push({
        id: "no",
        header: () => <span>STT</span>,
        cell: ({ row }) => <span>{row.index + 1}</span>,
        size: 60,
      });
    }

    tableColumnsDefine.push(...columnDefined);

    return tableColumnsDefine;
  }, []);

  return { columns, ...rest };
};

export default useColumnsDefine;

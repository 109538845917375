/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetBillsEntity } from '../models/GetBillsEntity';
import type { GetListOrderEntity } from '../models/GetListOrderEntity';
import type { GetListReportByAgencyEntity } from '../models/GetListReportByAgencyEntity';
import type { GetListReportByPCEntity } from '../models/GetListReportByPCEntity';
import type { GetListTransPayEntity } from '../models/GetListTransPayEntity';
import type { MobileGetBillsEntity } from '../models/MobileGetBillsEntity';
import type { PayBillFeesByCustomerCodeDto } from '../models/PayBillFeesByCustomerCodeDto';
import type { PayBillOrderEntityReturn } from '../models/PayBillOrderEntityReturn';
import type { PayBillOrderResultEntityReturn } from '../models/PayBillOrderResultEntityReturn';
import type { SendAgencyReportByEmailDto } from '../models/SendAgencyReportByEmailDto';
import type { SendPcReportByEmailDto } from '../models/SendPcReportByEmailDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserWalletServicesService {

    /**
     * @param requestBody 
     * @returns PayBillOrderResultEntityReturn 
     * @throws ApiError
     */
    public static servicesControllerPayBill(
requestBody: PayBillFeesByCustomerCodeDto,
): CancelablePromise<PayBillOrderResultEntityReturn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/services/elec/pay-bill',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bills should not be empty, Order contain bill error,Order contain bill invalid`,
                401: `Unauthorized`,
                403: `Forrbidden`,
            },
        });
    }

    /**
     * @param customerCodes String contains list of customer code separated by ','
     * @returns GetBillsEntity 
     * @throws ApiError
     */
    public static servicesControllerGetOrders(
customerCodes: string,
): CancelablePromise<GetBillsEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/services/elec/bills',
            query: {
                'customerCodes': customerCodes,
            },
            errors: {
                400: `Customer code invalid,Agency not support`,
                401: `Unauthorized`,
                403: `Forrbidden`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param transactionCode Search by Transaction code
     * @param orderCode Search by Order code
     * @param status Transaction Status
     * @param leader Leader's full name-phone number
     * @param userExec User Exec's full name or phone number
     * @param serviceType Service Type
     * @param fromDate Ngày đầu
     * @param toDate Ngày cuối
     * @param searchDetailContent Search keyword in detail contents 
     * @param page Page
     * @param pageSize Page size
     * @returns GetListTransPayEntity 
     * @throws ApiError
     */
    public static servicesControllerGetTransaction(
transactionCode?: string,
orderCode?: string,
status: 'Thành công' | 'Thất bại' | 'Đang chờ hủy' | 'Đã hủy' | 'Đang xử lý' | 'Tất cả' = 'Tất cả',
leader?: string,
userExec?: string,
serviceType?: string,
fromDate?: string,
toDate?: string,
searchDetailContent?: string,
page: string = '1',
pageSize: string = '10',
): CancelablePromise<GetListTransPayEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/services/elec/transactions',
            query: {
                'TransactionCode': transactionCode,
                'OrderCode': orderCode,
                'status': status,
                'leader': leader,
                'userExec': userExec,
                'serviceType': serviceType,
                'fromDate': fromDate,
                'toDate': toDate,
                'searchDetailContent': searchDetailContent,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forrbidden`,
            },
        });
    }

    /**
     * @param orderCode Order Code
     * @param searchDetailContent Search keyword in detail contents 
     * @param page Page
     * @param pageSize Page size
     * @returns PayBillOrderEntityReturn 
     * @throws ApiError
     */
    public static servicesControllerGetOneUser(
orderCode: string,
searchDetailContent?: string,
page: string = '1',
pageSize: string = '10',
): CancelablePromise<PayBillOrderEntityReturn> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/services/elec/order/{orderCode}',
            query: {
                'orderCode': orderCode,
                'searchDetailContent': searchDetailContent,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forrbidden`,
                404: `Order not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Report by PC 
     * @param fromTime From Time (UTC) in ISO8601 format
     * @param toTime To Time (UTC) in ISO8601 format
     * @param searchDetailContent Search keyword in detail contents 
     * @param page Page
     * @param pageSize Page size
     * @returns GetListReportByPCEntity 
     * @throws ApiError
     */
    public static servicesControllerReportByPc(
fromTime?: string,
toTime?: string,
searchDetailContent?: string,
page: string = '1',
pageSize: string = '10',
): CancelablePromise<GetListReportByPCEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/services/report/report-by-pc',
            query: {
                'fromTime': fromTime,
                'toTime': toTime,
                'searchDetailContent': searchDetailContent,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Send report by PC 
     * @param requestBody 
     * @returns any Accepted
     * @throws ApiError
     */
    public static servicesControllerSendReportByPc(
requestBody: SendPcReportByEmailDto,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/services/report/send-pc-report-mail',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Report by Agency 
     * @param compId PC Id
     * @param fromTime From Time
     * @param toTime To Time
     * @param searchDetailContent Search keyword in detail contents 
     * @param page Page
     * @param pageSize Page size
     * @returns GetListReportByAgencyEntity 
     * @throws ApiError
     */
    public static servicesControllerReportByAgency(
compId?: string,
fromTime?: string,
toTime?: string,
searchDetailContent?: string,
page: string = '1',
pageSize: string = '10',
): CancelablePromise<GetListReportByAgencyEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/services/report/report-by-agency',
            query: {
                'compId': compId,
                'fromTime': fromTime,
                'toTime': toTime,
                'searchDetailContent': searchDetailContent,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Send report by agency 
     * @param requestBody 
     * @returns any Accepted
     * @throws ApiError
     */
    public static servicesControllerSendReportByAgency(
requestBody: SendAgencyReportByEmailDto,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/services/report/send-agency-report-mail',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param orderCode Search by Order code
     * @param leader Leader's full name-phone number
     * @param userExec User Exec's full name or phone number
     * @param serviceType Service Type
     * @param fromDate Ngày đầu
     * @param toDate Ngày cuối
     * @param status Transaction Status
     * @param searchDetailContent Search keyword in detail contents 
     * @param page Page
     * @param pageSize Page size
     * @returns GetListOrderEntity 
     * @throws ApiError
     */
    public static servicesControllerGetListOrder(
orderCode?: string,
leader?: string,
userExec?: string,
serviceType?: string,
fromDate?: string,
toDate?: string,
status: 'Hoàn thành' | 'Đang xử lý' | 'Tất cả' = 'Tất cả',
searchDetailContent?: string,
page: string = '1',
pageSize: string = '10',
): CancelablePromise<GetListOrderEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/services/elec/orders',
            query: {
                'OrderCode': orderCode,
                'leader': leader,
                'userExec': userExec,
                'serviceType': serviceType,
                'fromDate': fromDate,
                'toDate': toDate,
                'status': status,
                'searchDetailContent': searchDetailContent,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param customerCodes String contains list of customer code separated by ','
     * @returns MobileGetBillsEntity 
     * @throws ApiError
     */
    public static servicesMobileControllerGetOrders(
customerCodes: string,
): CancelablePromise<MobileGetBillsEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/services/elec/bills-mobile',
            query: {
                'customerCodes': customerCodes,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forrbidden`,
            },
        });
    }

}

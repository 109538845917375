import { useQuery } from '@tanstack/react-query';
import { GetListOrderEntity, UserWalletServicesService } from 'services/openapi';

import { OrdersQueryKeys } from './constants';
import { isEmpty } from 'lodash';

type GetListOrdersDTO = {
  orderCode?: string;
  leader?: string;
  userExec?: string;
  serviceType?: string;
  fromDate?: string;
  toDate?: string;
  status: 'Hoàn thành' | 'Đang xử lý' | 'Tất cả';
  searchDetailContent?: string;
  page: string;
  pageSize: string;
};

export const useGetOrdersListQuery = (variables: GetListOrdersDTO) => {
  const queryKey = [OrdersQueryKeys.GET_ORDERS_LIST, variables];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<GetListOrderEntity, Error>({
    queryKey,
    queryFn: () =>
      UserWalletServicesService.servicesControllerGetListOrder(
        variables.orderCode?.trim(),
        variables.leader?.trim(),
        variables.userExec?.trim(),
        variables.serviceType,
        variables.fromDate?.trim(),
        variables.toDate?.trim(),
        variables.status,
        variables.searchDetailContent?.trim(),
        variables.page,
        variables.pageSize,
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: !isEmpty(variables)
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};

export enum UserServiceQueryKeys {
  CHECK_PHONE_NO = 'CHECK_PHONE_NO',
  GET_USER_PROFILE = 'GET_USER_PROFILE',
  GET_USERS_LIST = 'GET_USERS_LIST',
  GET_USER_INFO = 'GET_USER_INFO',
  GET_LEADERS_LIST = 'GET_LEADERS_LIST',
}

export enum CompanyWalletServiceQueryKeys {
  GET_COMPANY_INFO = 'GET_COMPANY_INFO',
}

export enum TransactionServiceQueryKeys {
  GET_USER_BALANCE = 'GET_USER_BALANCE',
  GET_BANK_INFO = 'GET_BANK_INFO',
}

export enum BankAccountServiceQueryKeys {
  GET_ACCOUNT_BANKS = 'GET_ACCOUNT_BANKS',
  DELETE_ACCOUNT_BANK = 'DELETE_ACCOUNT_BANK',
  GET_LIST_BANKS = 'GET_LIST_BANKS',
  CREATE_ACCOUNT_BANK = 'CREATE_ACCOUNT_BANK',
}

import { useMutation } from '@tanstack/react-query';
import { UserWalletManageService } from 'services/openapi';

type ActiveUserMutationVariables = {
  userId: string;
};

export const useInactiveUserMutation = () => {
  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    any,
    Error,
    ActiveUserMutationVariables
  >({
    mutationFn: (variables: ActiveUserMutationVariables) => {
      return UserWalletManageService.userManageControllerInactiveUser(variables.userId);
    },
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

import { useMutation } from '@tanstack/react-query';
import { UserAvatarGenerateSasUrlDto, UserGenerateSasUrlEntity, UserStorageService } from 'services/openapi';

export const useUserUploadAvatarMutation = () => {
  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    UserGenerateSasUrlEntity,
    Error,
    UserAvatarGenerateSasUrlDto & { file: File }
  >({
    mutationFn: ({ extention }: UserAvatarGenerateSasUrlDto) => {
      return UserStorageService.storageControllerGetUserUploadAvatarUrl({ extention });
    },
    onSuccess: async (data, { file }) => {
      const { sasUrl } = data;

      try {
        const res = await fetch(sasUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
            'x-ms-blob-type': 'BlockBlob',
          },
          body: file,
        });

        if (!res.ok) {
          throw error;
        }

        return data;
      } catch (error) {
        throw error;
      }
    },
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

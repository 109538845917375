import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateProfileUserDto, UpdateProfileUserEntity, BankAccountService, RemoveBankAccountEntity } from 'services/openapi';

import { BankAccountServiceQueryKeys } from '../constants';

export const useDeleteAccountBankMutation = () => {
  const queryClient = useQueryClient();

  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    RemoveBankAccountEntity,
    Error,
    string
  >({
    mutationFn: (variables: string) => {
      return BankAccountService.bankAccountControllerRemoveBankAccount(variables);
    },
    onSuccess: () => Promise.all([queryClient.invalidateQueries([BankAccountServiceQueryKeys.DELETE_ACCOUNT_BANK])]),
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

import { useQuery } from '@tanstack/react-query';
import { BankAccountService, GetListBankAccountEntity, GetListBankEntity } from 'services/openapi';

import { RequestWithdrawQueryKeys } from '../constants';

export const useGetListAccountBanksQuery = () => {
  const queryKey = [RequestWithdrawQueryKeys.GET_LIST_ACCOUNT_BANKS];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<GetListBankAccountEntity, Error>({
    queryKey,
    queryFn: () => BankAccountService.bankAccountControllerGetListBankAccount(),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: Infinity,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data,
    isError,
    queryKey,
    refetch,
  };
};

import classmane from 'classnames';
import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Card = (props: Props) => {
  const { children, className } = props;

  return <div className={classmane('card-wrapper', className)}> {children}</div>;
};

export default Card;

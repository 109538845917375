const defaultState = null;

export const SET_SYMBOLS = 'SET_SYMBOLS';

export const symbolsReducer = (state = defaultState, action: any) => {
  const { type, data, error } = action;
  if (type === SET_SYMBOLS) {
    if (error) return { error };
    if (data) return data;
  }
  return state;
};

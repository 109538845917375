/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgencyEntity } from '../models/AgencyEntity';
import type { CompanyEntity } from '../models/CompanyEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserWalletCompanyInfoService {

    /**
     * @returns CompanyEntity 
     * @throws ApiError
     */
    public static companyControllerFindAll(): CancelablePromise<Array<CompanyEntity>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/companyinfo',
            errors: {
                401: `Unauthorized`,
                403: `Forrbidden`,
            },
        });
    }

    /**
     * @returns AgencyEntity 
     * @throws ApiError
     */
    public static companyControllerAgencyFindAll(): CancelablePromise<Array<AgencyEntity>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/agencies',
            errors: {
                401: `Unauthorized`,
                403: `Forrbidden`,
            },
        });
    }

}

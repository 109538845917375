import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ElectricQueryKeys } from 'services/electric';
import { GetDetailTransactionEntity, UserWalletTransactionService } from 'services/openapi';

import { TransactionServiceKeys } from '../queries';

type RequestCancelTransactionType = {
  transactionCode: string;
};

export const useApproveCancelTransactionMutation = () => {
  const queryClient = useQueryClient();

  const { isLoading, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    GetDetailTransactionEntity,
    Error,
    RequestCancelTransactionType
  >({
    mutationFn: (transactionCode: { transactionCode: string }) => {
      return UserWalletTransactionService.transactionControllerApproveCancelTransaction(String(transactionCode));
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries([ElectricQueryKeys.GET_ELECTRIC_BILLS_DETAILS]),
        queryClient.invalidateQueries([TransactionServiceKeys.GET_TRANSACTION_DETAILS]),
      ]),
  });

  return {
    isLoading,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};

import { useQuery } from '@tanstack/react-query';
import { GetDetailTransactionEntity, UserWalletTransactionService } from 'services/openapi';

import { TransactionServiceKeys } from './constants';

export const useGetTransactionDetailsQuery = (transactionCode: string) => {
  const queryKey = [TransactionServiceKeys.GET_TRANSACTION_DETAILS];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    GetDetailTransactionEntity,
    Error
  >({
    queryKey,
    queryFn: () => UserWalletTransactionService.transactionControllerGetDetailTransaction(transactionCode),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data,
    isError,
    queryKey,
    refetch,
  };
};

import 'react-calendar/dist/Calendar.css';
import 'react-datetime-picker/dist/DateTimePicker.css';

import { IInputProps } from 'modules';
import React, { useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';

export const InputDateTime = (props: IInputProps) => {
  const [value, setValue] = useState(null) as any;

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  const handleChange = (date: any) => {
    let time: any;

    if (date) time = new Date(date).getTime();

    setValue(time);

    props.onChange(new Date(time).toUTCString());

    props.onChangeCallback?.(new Date(time).toUTCString());
  };

  const convertTime = (time: any) => {
    if (!time) return;
    return new Date(time);
  };

  return (
    <DateTimePicker
      className="InputDateTime"
      calendarClassName="InputDateTimeCalendar"
      clockClassName="InputDateTimeClock"
      // maxDetail={maxDetail}
      disableClock={true}
      onChange={handleChange}
      // minDate={convertTime(minDate)}
      // maxDate={convertTime(maxDate)}
      // time={true}
      format={props?.dateTimeFormat || 'dd/MM/yyyy HH:mm:ss'}
      value={convertTime(value)}
      locale="vi-VN"
      disabled={props.isDisabled}
      onCalendarClose={() => props.onTouched()}
      onBlur={() => props.onTouched()}
      clearIcon={null}
    />
  );
};

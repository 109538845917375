import { menusRenderer } from 'configs/menu';
import { Icon } from 'modules';
import React, { useState } from 'react';

import MenuItems from './MenuItem';
import { MainRouteKeys } from 'routes/enums';

const Navigator = (): JSX.Element => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={`Navigator ${isSidebarOpen ? 'open' : ''}`}>
      <div className="menu">
        <a className='logo' href= {MainRouteKeys.PersonalInfo}><img src="/assets/images/logo-symbol-white.png" alt="" className="logo" /></a>
        <button className="toggle-button toggle-button_open" type="button" onClick={toggleSidebar}>
          <Icon.Menu />
        </button>
        <button className="toggle-button" onClick={toggleSidebar}>
          <Icon.Menu />
        </button>

        {menusRenderer().map((menu) => {
          return <MenuItems data={menu} key={menu.path} onClickCallback={toggleSidebar} />;
        })}
      </div>
    </div>
  );
};

export default Navigator;

import React, { useEffect, useState } from 'react';

type Props = {
  value?: string | number;
  onChange?: (value: string | number) => void;
  onKeyDown?: (event: any) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export const DebouncedInput = (props: Props) => {
  const { value: initialValue, onChange, debounce = 500, onKeyDown, ...rest } = props;

  const [value, setValue] = useState<any>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange?.(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return <input {...rest} value={value} onChange={(e) => setValue(e.target.value)}
    onKeyDown={(e) => onKeyDown?.(e)} />;
};

const defaultState = null;

export const SET_USER_INTERNAL_WALLETS = 'SET_USER_INTERNAL_WALLETS';

export const userInternalWalletsReducer = (state = defaultState, action: any) => {
  const { type, data, error } = action;
  if (type === SET_USER_INTERNAL_WALLETS) {
    if (error) return { error };
    if (data) return data;
  }
  return state;
};

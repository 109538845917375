/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponseEntity } from '../models/BaseResponseEntity';
import type { GetBankInfo } from '../models/GetBankInfo';
import type { GetDetailTransactionEntity } from '../models/GetDetailTransactionEntity';
import type { GetGpQrEntity } from '../models/GetGpQrEntity';
import type { GetListPointTransactionEntity } from '../models/GetListPointTransactionEntity';
import type { GetUserBalanceEntity } from '../models/GetUserBalanceEntity';
import type { InqueryTpbStatementEntity } from '../models/InqueryTpbStatementEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserWalletTransactionService {

    /**
     * @returns GetUserBalanceEntity 
     * @throws ApiError
     */
    public static transactionControllerGetUserBalance(): CancelablePromise<GetUserBalanceEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/transaction/balance',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param locale 
     * @returns GetBankInfo 
     * @throws ApiError
     */
    public static transactionControllerGetBankInfo(
locale: string,
): CancelablePromise<GetBankInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/transaction/bank-info/{locale}',
            path: {
                'locale': locale,
            },
        });
    }

    /**
     * @param transactionCode 
     * @returns GetDetailTransactionEntity 
     * @throws ApiError
     */
    public static transactionControllerGetDetailTransaction(
transactionCode: string,
): CancelablePromise<GetDetailTransactionEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/transactions/{transactionCode}',
            path: {
                'transactionCode': transactionCode,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param transactionCode 
     * @returns GetDetailTransactionEntity 
     * @throws ApiError
     */
    public static transactionControllerRequestCancelTransaction(
transactionCode: string,
): CancelablePromise<GetDetailTransactionEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/transactions/{transactionCode}/request-cancel',
            path: {
                'transactionCode': transactionCode,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param transactionCode 
     * @returns GetDetailTransactionEntity 
     * @throws ApiError
     */
    public static transactionControllerApproveCancelTransaction(
transactionCode: string,
): CancelablePromise<GetDetailTransactionEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/transactions/{transactionCode}/approve-cancel',
            path: {
                'transactionCode': transactionCode,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * List Point Transactions
     * @param transactionStatus Search by Trans's status
     * @param fromTime From Time
     * @param toTime To Time
     * @param excutedOnlySelf Get only trans excuted by self 
     * @param transactionCode Search by Trans's code
     * @param transactionType Search by Trans's type
     * @param fromUserSearchString Search by user's name or phone number(User Send)
     * @param toUserSearchString Search by user's name or phone number(User Receive)
     * @param searchDetailContent Search keyword in detail contents 
     * @param page Page
     * @param pageSize Page size
     * @returns GetListPointTransactionEntity 
     * @throws ApiError
     */
    public static transactionControllerGetListPointTrans(
transactionStatus: 'Tất cả' | 'Thành công' | 'Thất bại' | 'Đã hủy' | 'Đang chờ hủy' | 'Đang xử lý',
fromTime: string,
toTime: string,
excutedOnlySelf: boolean,
transactionCode?: string,
transactionType?: 'Tất cả' | 'Thanh toán hóa đơn-Tiền điện' | 'Nạp điểm' | 'Chuyển điểm' | 'Nhận điểm' | 'Hoàn điểm' | 'Rút điểm',
fromUserSearchString?: string,
toUserSearchString?: string,
searchDetailContent?: string,
page: string = '1',
pageSize: string = '10',
): CancelablePromise<GetListPointTransactionEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/transactions/get-list-point-trans',
            query: {
                'transactionCode': transactionCode,
                'transactionType': transactionType,
                'transactionStatus': transactionStatus,
                'fromTime': fromTime,
                'toTime': toTime,
                'fromUserSearchString': fromUserSearchString,
                'toUserSearchString': toUserSearchString,
                'searchDetailContent': searchDetailContent,
                'excutedOnlySelf': excutedOnlySelf,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @param fromDate 
     * @param toDate 
     * @param page 
     * @param size 
     * @param accountNumber 
     * @returns InqueryTpbStatementEntity 
     * @throws ApiError
     */
    public static transactionControllerInqueryTpbStatement(
fromDate: string,
toDate: string,
page: string,
size: string,
accountNumber: string,
): CancelablePromise<InqueryTpbStatementEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/transactions/tpb/inquery-statement',
            query: {
                'fromDate': fromDate,
                'toDate': toDate,
                'page': page,
                'size': size,
                'accountNumber': accountNumber,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param transactionIds 
     * @returns BaseResponseEntity 
     * @throws ApiError
     */
    public static transactionControllerResendTpbTransactions(
transactionIds: string,
): CancelablePromise<BaseResponseEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user-wallet/transactions/tpb/resend',
            query: {
                'transactionIds': transactionIds,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param amount Amount
     * @returns GetGpQrEntity 
     * @throws ApiError
     */
    public static transactionControllerGetGalaxyPayQr(
amount: string,
): CancelablePromise<GetGpQrEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user-wallet/transactions/galaxypay/qr',
            query: {
                'amount': amount,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}

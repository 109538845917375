/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserAvatarGenerateSasUrlDto } from '../models/UserAvatarGenerateSasUrlDto';
import type { UserGenerateSasUrlEntity } from '../models/UserGenerateSasUrlEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserStorageService {

    /**
     * Get upload-avatar's sas url
     * @param requestBody 
     * @returns UserGenerateSasUrlEntity 
     * @throws ApiError
     */
    public static storageControllerGetUserUploadAvatarUrl(
requestBody: UserAvatarGenerateSasUrlDto,
): CancelablePromise<UserGenerateSasUrlEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/storage/user-upload-avatar-url',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
    }

}

import React from 'react';
import { useGetUserProfileQuery, useGetBalanceQuery } from 'services';

import { Image } from '../image';

const Header = (): JSX.Element => {
  const { data } = useGetUserProfileQuery();
  const { data: userBalance } = useGetBalanceQuery();

  const currencyFormat = (num: any | unknown): any => {
    if (num == null) return "";
    const parts = num?.toString()?.split(".");
    return (
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
      (parts[1] ? "," + parts[1] : "")
    );
  };

  return (
    <div className="UserHead">
      <div className="profile ctas">
        <button type="button" className="btn dropdowns">
          <Image src={data?.avatar || '/assets/images/no-image.jpg'} type="avatar" />

          <div className="info">
            <div className="name">{data?.fullName}</div>
            <div className="phonenumber">{data?.phoneNumber}</div>
            <div className="email">{data?.email}</div>
            <div className="balance">{(currencyFormat(userBalance?.balance ?? 0)) + ' đ'}</div>
          </div>

          {/* <div className="toggleIcon">
            <Icon.ArrowDown />
          </div> */}

          {/* <div className="options">
            <div className="wraper">
              <div className="item" onClick={() => navigate(MainRouteKeys.Personal)}>
                <Icon.Setting />
                <span>Cài đặt</span>
              </div>
              <div className="item btnLogout" onClick={handleLogout}>
                {isLoading ? <Icon.Loading /> : <Icon.Logout />}
                <span>Đăng xuất</span>
              </div>
            </div>
          </div> */}
        </button>
      </div>
    </div>
  );
};

export default Header;
